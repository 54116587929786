import debug from 'debug'

import {
  getLanguage,
  getCurrency,
  getCurrencyDigits
} from '~/client/assets/js/common/render-data'

const log = debug('renderapp:render-data/price-format')

log('`renderapp` is awake')

export default function getPriceFormat (currencyDigits = 0) {
  /**
   *  log('getPriceFormat')
   */

  const language = getLanguage()
  const currency = getCurrency()
  const minimumFractionDigits = (
    isNaN(currencyDigits)
      ? getCurrencyDigits()
      : currencyDigits
  )

  return (
    Intl.NumberFormat(language, { style: 'currency', currency, minimumFractionDigits })
  )
}
