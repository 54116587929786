/*!
 * renderapp.js
 * http://renderapp.co
 *
 * Copyright 2022 Renderapp Limited
 */

import debug from 'debug'

import {
  normalise
} from '~/client/assets/js/common/render-data'

import {
  getDefaultConfigurationFromButtonObjects
} from './render-data/button-objects'

const log = debug('renderapp:default-configuration')

log('`renderapp` is awake')

export const defaultConfigurationMap = new Map()

export function hasDefaultConfigurationFor (configuration = '') {
  log('hasDefaultConfigurationFor')

  let defaultConfiguration = false

  if (configuration.includes('+170')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = true
    } else if (configuration.includes('+RPC')) {
      defaultConfiguration = true
    }
  } else if (configuration.includes('+175')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = true
    } else if (configuration.includes('+RPC')) {
      defaultConfiguration = true
    }
  } else if (configuration.includes('+270')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = true
    } else if (configuration.includes('+RPC')) {
      defaultConfiguration = true
    }
  } else if (configuration.includes('+310')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = true
    } else if (configuration.includes('+RPC')) {
      defaultConfiguration = true
    }
  } else if (configuration.includes('+360')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = true
    } else if (configuration.includes('+RPC')) {
      defaultConfiguration = true
    }
  } else if (configuration.includes('+420')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = true
    } else if (configuration.includes('+RPC')) {
      defaultConfiguration = true
    }
  } else if (configuration.includes('+620')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = true
    } else if (configuration.includes('+RPC')) {
      defaultConfiguration = true
    }
  } else if (configuration.includes('+275')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = true
    } else if (configuration.includes('+RPC')) {
      defaultConfiguration = true
    }
  } else if (configuration.includes('+340')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = true
    } else if (configuration.includes('+RPC')) {
      defaultConfiguration = true
    }
  } else if (configuration.includes('+485')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = true
    } else if (configuration.includes('+RPC')) {
      defaultConfiguration = true
    }
  } else if (configuration.includes('+CSR')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = true
    }
  } else if (configuration.includes('+42C')) {
    defaultConfiguration = true
  } else if (configuration.includes('+SUK')) {
    defaultConfiguration = true
  } else if (configuration.includes('+S6H')) {
    defaultConfiguration = true
  } else if (configuration.includes('+S2K')) {
    defaultConfiguration = true
  } else if (configuration.includes('+S6U')) {
    defaultConfiguration = true
  } else if (configuration.includes('+S2U')) {
    defaultConfiguration = true
  } else if (configuration.includes('+SEU')) {
    defaultConfiguration = true
  }

  return defaultConfiguration
}

export function getDefaultConfigurationFor (configuration = '') {
  log('getDefaultConfigurationFor')

  let defaultConfiguration = ''

  if (configuration.includes('+170')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = '+170+SPC'
    } else if (configuration.includes('+RPC')) {
      defaultConfiguration = '+170+RPC'
    }
  } else if (configuration.includes('+175')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = '+175+SPC'
    } else if (configuration.includes('+RPC')) {
      defaultConfiguration = '+175+RPC'
    }
  } else if (configuration.includes('+270')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = '+270+SPC'
    } else if (configuration.includes('+RPC')) {
      defaultConfiguration = '+270+RPC'
    }
  } else if (configuration.includes('+310')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = '+310+SPC'
    } else if (configuration.includes('+RPC')) {
      defaultConfiguration = '+310+RPC'
    }
  } else if (configuration.includes('+360')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = '+360+SPC'
    } else if (configuration.includes('+RPC')) {
      defaultConfiguration = '+360+RPC'
    }
  } else if (configuration.includes('+420')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = '+420+SPC'
    } else if (configuration.includes('+RPC')) {
      defaultConfiguration = '+420+RPC'
    }
  } else if (configuration.includes('+620')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = '+620+SPC'
    } else if (configuration.includes('+RPC')) {
      defaultConfiguration = '+620+RPC'
    }
  } else if (configuration.includes('+275')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = '+275+SPC'
    } else if (configuration.includes('+RPC')) {
      defaultConfiguration = '+275+RPC'
    }
  } else if (configuration.includes('+340')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = '+340+SPC'
    } else if (configuration.includes('+RPC')) {
      defaultConfiguration = '+340+RPC'
    }
  } else if (configuration.includes('+485')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = '+485+SPC'
    } else if (configuration.includes('+RPC')) {
      defaultConfiguration = '+485+RPC'
    }
  } else if (configuration.includes('+CSR')) {
    if (configuration.includes('+SPC')) {
      defaultConfiguration = '+CSR+SPC'
    }
  } else if (configuration.includes('+42C')) {
    defaultConfiguration = '+42C'
  } else if (configuration.includes('+SUK')) {
    defaultConfiguration = '+SUK'
  } else if (configuration.includes('+S6H')) {
    defaultConfiguration = '+S6H'
  } else if (configuration.includes('+S2K')) {
    defaultConfiguration = '+S2K'
  } else if (configuration.includes('+S6U')) {
    defaultConfiguration = '+S6U'
  } else if (configuration.includes('+S2U')) {
    defaultConfiguration = '+S2U'
  } else if (configuration.includes('+SEU')) {
    defaultConfiguration = '+SEU'
  }

  if (!defaultConfiguration) return ''

  if (defaultConfigurationMap.has(defaultConfiguration)) {
    defaultConfiguration = defaultConfigurationMap.get(defaultConfiguration)
  } else {
    defaultConfigurationMap.set(defaultConfiguration, (defaultConfiguration = normalise(defaultConfiguration.concat(getDefaultConfigurationFromButtonObjects(defaultConfiguration)))))
  }

  return defaultConfiguration
}

export function hasDefaultConfiguration (frontEndRule = '', configuration = '') {
  log('hasDefaultConfiguration')

  let defaultConfiguration = false

  // S PACK
  if (frontEndRule === '+170' || (frontEndRule === '+SPC' && configuration.includes('+170'))) {
    defaultConfiguration = true
  } else if (frontEndRule === '+175' || (frontEndRule === '+SPC' && configuration.includes('+175'))) {
    defaultConfiguration = true
  } else if (frontEndRule === '+270' || (frontEndRule === '+SPC' && configuration.includes('+270'))) {
    defaultConfiguration = true
  } else if (frontEndRule === '+310' || (frontEndRule === '+SPC' && configuration.includes('+310'))) {
    defaultConfiguration = true
  } else if (frontEndRule === '+360' || (frontEndRule === '+SPC' && configuration.includes('+360'))) {
    defaultConfiguration = true
  } else if (frontEndRule === '+420' || (frontEndRule === '+SPC' && configuration.includes('+420'))) {
    defaultConfiguration = true
  } else if (frontEndRule === '+620' || (frontEndRule === '+SPC' && configuration.includes('+620'))) {
    defaultConfiguration = true
  } else if (frontEndRule === '+275' || (frontEndRule === '+SPC' && configuration.includes('+275'))) {
    defaultConfiguration = true
  } else if (frontEndRule === '+340' || (frontEndRule === '+SPC' && configuration.includes('+340'))) {
    defaultConfiguration = true
  } else if (frontEndRule === '+485' || (frontEndRule === '+SPC' && configuration.includes('+485'))) {
    defaultConfiguration = true
  } else if (frontEndRule === '+CSR' || (frontEndRule === '+SPC' && configuration.includes('+CSR'))) {
    defaultConfiguration = true
  } else if (frontEndRule === '+RPC') {
    // R PACK
    if (configuration.includes('+170')) {
      defaultConfiguration = true
    } else if (configuration.includes('+175')) {
      defaultConfiguration = true
    } else if (configuration.includes('+270')) {
      defaultConfiguration = true
    } else if (configuration.includes('+310')) {
      defaultConfiguration = true
    } else if (configuration.includes('+360')) {
      defaultConfiguration = true
    } else if (configuration.includes('+420')) {
      defaultConfiguration = true
    } else if (configuration.includes('+620')) {
      defaultConfiguration = true
    } else if (configuration.includes('+275')) {
      defaultConfiguration = true
    } else if (configuration.includes('+340')) {
      defaultConfiguration = true
    } else if (configuration.includes('+485')) {
      defaultConfiguration = true
    }
  } else if (frontEndRule === '+42C') {
    defaultConfiguration = true
  } else if (frontEndRule === '+SUK') { // SUPER SEVEN
    defaultConfiguration = true
  } else if (frontEndRule === '+S6H') { // SUPER SEVEN
    defaultConfiguration = true
  } else if (frontEndRule === '+S2K') { // SUPER SEVEN
    defaultConfiguration = true
  } else if (frontEndRule === '+S6U') { // SUPER SEVEN
    defaultConfiguration = true
  } else if (frontEndRule === '+S2U') { // SUPER SEVEN
    defaultConfiguration = true
  } else if (frontEndRule === '+SEU') {
    defaultConfiguration = true
  }

  return defaultConfiguration
}

export function getDefaultConfiguration (frontEndRule = '', configuration = '') {
  log('getDefaultConfiguration')

  let defaultConfiguration = ''

  // S PACK
  if (frontEndRule === '+170' || (frontEndRule === '+SPC' && configuration.includes('+170'))) {
    defaultConfiguration = '+170+SPC'
  } else if (frontEndRule === '+175' || (frontEndRule === '+SPC' && configuration.includes('+175'))) {
    defaultConfiguration = '+175+SPC'
  } else if (frontEndRule === '+270' || (frontEndRule === '+SPC' && configuration.includes('+270'))) {
    defaultConfiguration = '+270+SPC'
  } else if (frontEndRule === '+310' || (frontEndRule === '+SPC' && configuration.includes('+310'))) {
    defaultConfiguration = '+310+SPC'
  } else if (frontEndRule === '+360' || (frontEndRule === '+SPC' && configuration.includes('+360'))) {
    defaultConfiguration = '+360+SPC'
  } else if (frontEndRule === '+420' || (frontEndRule === '+SPC' && configuration.includes('+420'))) {
    defaultConfiguration = '+420+SPC'
  } else if (frontEndRule === '+620' || (frontEndRule === '+SPC' && configuration.includes('+620'))) {
    defaultConfiguration = '+620+SPC'
  } else if (frontEndRule === '+275' || (frontEndRule === '+SPC' && configuration.includes('+275'))) {
    defaultConfiguration = '+275+SPC'
  } else if (frontEndRule === '+340' || (frontEndRule === '+SPC' && configuration.includes('+340'))) {
    defaultConfiguration = '+340+SPC'
  } else if (frontEndRule === '+485' || (frontEndRule === '+SPC' && configuration.includes('+485'))) {
    defaultConfiguration = '+485+SPC'
  } else if (frontEndRule === '+CSR' || (frontEndRule === '+SPC' && configuration.includes('+CSR'))) {
    defaultConfiguration = '+CSR+SPC'
  } else if (frontEndRule === '+RPC') {
    // R PACK
    if (configuration.includes('+170')) {
      defaultConfiguration = '+170+RPC'
    } else if (configuration.includes('+175')) {
      defaultConfiguration = '+175+RPC'
    } else if (configuration.includes('+270')) {
      defaultConfiguration = '+270+RPC'
    } else if (configuration.includes('+310')) {
      defaultConfiguration = '+310+RPC'
    } else if (configuration.includes('+360')) {
      defaultConfiguration = '+360+RPC'
    } else if (configuration.includes('+420')) {
      defaultConfiguration = '+420+RPC'
    } else if (configuration.includes('+620')) {
      defaultConfiguration = '+620+RPC'
    } else if (configuration.includes('+275')) {
      defaultConfiguration = '+275+RPC'
    } else if (configuration.includes('+340')) {
      defaultConfiguration = '+340+RPC'
    } else if (configuration.includes('+485')) {
      defaultConfiguration = '+485+RPC'
    }
  } else if (frontEndRule === '+42C') {
    defaultConfiguration = '+42C'
  } else if (frontEndRule === '+SUK') { // SUPER SEVEN
    defaultConfiguration = '+SUK'
  } else if (frontEndRule === '+S6H') { // SUPER SEVEN
    defaultConfiguration = '+S6H'
  } else if (frontEndRule === '+S2K') { // SUPER SEVEN
    defaultConfiguration = '+S2K'
  } else if (frontEndRule === '+S6U') { // SUPER SEVEN
    defaultConfiguration = '+S6U'
  } else if (frontEndRule === '+S2U') { // SUPER SEVEN
    defaultConfiguration = '+S2U'
  } else if (frontEndRule === '+SEU') {
    defaultConfiguration = '+SEU'
  }

  if (!defaultConfiguration) return ''

  if (defaultConfigurationMap.has(defaultConfiguration)) {
    defaultConfiguration = defaultConfigurationMap.get(defaultConfiguration)
  } else {
    defaultConfigurationMap.set(defaultConfiguration, (defaultConfiguration = normalise(defaultConfiguration.concat(getDefaultConfigurationFromButtonObjects(defaultConfiguration)))))
  }

  return defaultConfiguration
}
