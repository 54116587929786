/*!
 * renderapp.js
 * http://renderapp.co
 *
 * Copyright 2022 Renderapp Limited
 */

import debug from 'debug'

import {
  getWindowLocationOrigin,
  getWindowLocationHostname,
  getWindowLocationHref,
  getWindowLocationPort,
  isSecure,
  isDefaultPort,
  isDefaultSecurePort
} from '@sequencemedia/device'

import {
  queryMatchMediaExtraSmall,
  queryMatchMediaSmall,
  queryMatchMediaMedium,
  queryMatchMediaLarge,
  queryMatchMediaExtraLarge
} from '~/client/assets/js/common/device/match-media/breakpoints'

import {
  isVertical
} from '~/client/assets/js/common/device'

import {
  DEFAULT_WIDTH,
  DEFAULT_HEIGHT,
  DEFAULT_FOV,

  MODELS,

  WEB,
  PRERENDER,
  WEBGL,
  REVIEW,

  getConfiguration,
  setConfiguration,
  hasConfiguration,

  getBackendConfiguration,
  setBackendConfiguration,
  hasBackendConfiguration,

  getRegionCode,
  setRegionCode,
  hasRegionCode,

  setRegionConfiguration,
  setCurrency,
  setLanguage,
  setCurrencyDigits,

  getSocket,
  setSocket,
  hasSocket,

  getCode,
  setCode,
  hasCode,

  getWidth,
  setWidth,
  hasWidth,

  getHeight,
  setHeight,
  hasHeight,

  getQuality,
  setQuality,
  hasQuality,

  setCameras,

  hasWebGLCameras,
  getWebGLCameras,
  setWebGLCameras,

  hasReviewCameras,
  getReviewCameras,
  setReviewCameras,

  getCamera,
  setCamera,

  hasWebGLCamera,
  getWebGLCamera,
  setWebGLCamera,

  getPageMode,
  setPageMode,
  hasPageMode,

  getPricesFrom,
  hasPricesIn,

  getConfigurationFrom,
  hasConfigurationIn,

  getBackendConfigurationFrom,
  hasBackendConfigurationIn,

  getRegionCodeFrom,
  hasRegionCodeIn,

  getRegionConfigurationFrom,
  hasRegionConfigurationIn,

  getCurrencyFrom,
  hasCurrencyIn,

  getLanguageFrom,
  hasLanguageIn,

  getCurrencyDigitsFrom,
  hasCurrencyDigitsIn,

  getSocketFrom,
  hasSocketIn,

  hasCamerasIn,
  getCamerasFrom,

  hasWebGLCamerasIn,
  getWebGLCamerasFrom,

  hasReviewCamerasIn,
  getReviewCamerasFrom,

  getPageModeFrom,
  hasPageModeIn,
  isPageModeWeb,
  isPageModePreRender,
  isPageModeWebGL,
  isPageModeDealership,
  isPageModePlus,
  isPageModeVR,
  isPageModeReview,

  setPrices,
  setPriceColumnRules,
  setButtonObjects,

  normalise,

  defaultData
} from '~/client/assets/js/common/render-data'

import {
  hasDefaultConfiguration,
  getDefaultConfiguration
} from './default-configuration'

import {
  updateUIElementSelected
} from './render-data/ui-element/change'

import {
  renderUIElementContainer,
  updateUIElementContainer
} from './render-data/ui-element-container/create'

import {
  updateUIElementContainerClassList,

  renderAllButtonObjects,
  renderButtonObjects
} from './render-data/ui-element-container/change'

import {
  resetTimeout as resetLoadingTimeout,
  createShowTimeout as createShowLoadingTimeout,
  createHideTimeout as createHideLoadingTimeout
} from './render-data/ui/loading'

import render from './render-data/ui'

import {
  isSelected,
  isVisible,
  isEnabled,

  hasButtonObjectCameraIndices,
  getButtonObjectCameraIndices,

  hasButtonObjectCamera,
  getButtonObjectCamera,

  hasButtonObjectWebGLCamIDs,
  getButtonObjectWebGLCamIDs,

  getButtonObjectGroup,

  hasButtonObjectUIElementContainer,
  getButtonObjectUIElementContainer,

  hasButtonObjectUIElement,
  getButtonObjectUIElement,

  hasButtonObjectFrontEndRule,
  getButtonObjectFrontEndRule,

  hasButtonObjectPairWithRule,
  getButtonObjectPairWithRule
} from './render-data/button-object'

import {
  updateAllButtonObjectsChanged,

  resetAllButtonObjectsChanged,

  resetButtonObjectsChangedForGroup,

  updateAllButtonObjectsSelected,
  updateAllButtonObjectsDisabled,

  updateAllButtonObjectsPrices
} from './render-data/button-objects/update'

import {
  getAllButtonObjects,

  getGroupsFromButtonObjects,

  getButtonObjectsForGroupModels,
  getButtonObjectsForGroupPack,

  getConfigurationFromButtonObjects,
  getBackendConfigurationFromButtonObjects
} from './render-data/button-objects'

import {
  DEFAULT_CAMERA_IMAGE_SRC,
  DEFAULT_CAMERA_IMAGE_ALT,

  DEFAULT_CAMERA_IMAGE_CACHE,
  CURRENT_CAMERA_IMAGE_CACHE,

  DEFAULT_CAMERA_IMAGE_NAME_CACHE,
  CURRENT_CAMERA_IMAGE_NAME_CACHE,

  createCurrentCameraImageFromCache,
  createCameraImageName
} from './render-data/camera-image'

import {
  getDefaultCamera,
  getCurrentCamera,
  getCamerasFor
} from './render-data/cameras'

import getPriceColumn from './render-data/price-column'

import transformToButtonObject from './render-data'

const log = debug('renderapp')
const info = debug('renderapp/info')

log('`renderapp` is awake')

/**
 *  https://stackoverflow.com/a/11379802/11416293
 *
 *  And http://bl.ocks.org/abernier/3070589
 *
 *    "Assuming a location of http://example.org:8888/foo/bar#bang):
 *
 *      - hostname gives you `example.org`
 *      - host gives you `example.org:8888`"
 */

let socket

// Settings
const scrollMultiplier = 15

// Review
let imagesToLoad = 0

// LIGHTGL
let timeout

let gl
let webGLMessage

let ratioFactor = 0
const multiplier = 0.25

let e_wirecolor_mesh // eslint-disable-line camelcase
let e_wirecolor_blocker_mesh // eslint-disable-line camelcase
let i_wirecolor_mesh // eslint-disable-line camelcase
let i_wirecolor_blocker_mesh // eslint-disable-line camelcase

// Show canvas (Web GL)
function showCanvas () {
  /**
   *  log('showCanvas')
   */

  const gameContainer = document.getElementById('gameContainer')
  if (gameContainer) {
    const {
      requestAnimationFrame = function requestAnimationFrame () {
        log('`requestAnimationFrame` is not available')
      }
    } = global

    requestAnimationFrame(() => {
      const {
        classList
      } = gameContainer

      classList.remove('canvasHide')
      classList.add('canvasShow')
    })
  }
}

// Hide canvas (Web GL)
function hideCanvas () {
  /**
   *  log('hideCanvas')
   */

  const gameContainer = document.getElementById('gameContainer')
  if (gameContainer) {
    const {
      requestAnimationFrame = function requestAnimationFrame () {
        log('`requestAnimationFrame` is not available')
      }
    } = global

    requestAnimationFrame(() => {
      const {
        classList
      } = gameContainer

      classList.remove('canvasShow')
      classList.add('canvasHide')
    })
  }
}

// Utilities
function clamp (val, min, max) {
  /**
   *  log('clamp')
   */
  return Math.min(Math.max(val, min), max)
}

function distance (x1, y1, x2, y2) {
  /**
   *  log('distance')
   */
  return Math.sqrt(Math.pow((x2 - x1), 2) + Math.pow((y2 - y1), 2))
}

function objectFitCover () {
  /**
   *  log('objectFitCover')
   */
  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  requestAnimationFrame(() => {
    const gameContainer = document.getElementById('gameContainer')

    const values = global.getComputedStyle(gameContainer)
    const containerWidth = parseInt(values.getPropertyValue('width'))
    const containerHeight = parseInt(values.getPropertyValue('height'))
    const containerRatio = containerWidth / containerHeight
    const imageRatio = getWidth() / getHeight()
    const f = imageRatio / containerRatio

    if (f < 1 && ratioFactor != -1) { // eslint-disable-line eqeqeq
      ratioFactor = -1
      gl.canvas.removeAttribute('style')
      gl.canvas.style.width = '100%'
      gl.canvas.style.top = '50%'
      gl.canvas.style.transform = 'translate(-50%, -50%)'
    } else if (f > 1 && ratioFactor != 1) { // eslint-disable-line eqeqeq
      ratioFactor = 1
      gl.canvas.removeAttribute('style')
      gl.canvas.style.height = '100%'
      gl.canvas.style.left = 'center'
      gl.canvas.style.transform = 'translateX(-50%)'
    } else if (f == 0.0 && ratioFactor != 0) { // eslint-disable-line eqeqeq
      gl.canvas.removeAttribute('style')
      ratioFactor = 0
    }
  })
}

function glDistance (touch1, touch2) {
  /**
   *  log('glDistance')
   */
  return Math.sqrt(Math.pow(touch1.clientX - touch2.clientX, 2) + Math.pow(touch1.clientY - touch2.clientY, 2))
}

/**
 *  Render Data
 */

export function getFindWebGLCameraForWebGLCameraID (webGLCameraID) {
  /**
   *  log('getSomeWebGLCameraForWebGLCameraID')
   */

  return (buttonObject) => webGLCameraID === getButtonObjectFrontEndRule(buttonObject)
}

export function getFindWebGLCameraForButtonObjects (buttonObjects) {
  /**
   *  log('getFindWebGLCameraForButtonObjects')
   */

  return ({ ID }) => buttonObjects.some(getFindWebGLCameraForWebGLCameraID('+'.concat(ID)))
}

export function getRenderDataFromURLSearchParams () {
  log('getRenderDataFromURLSearchParams')

  const href = getWindowLocationHref()

  const url = new URL(href)

  const {
    searchParams
  } = url

  const code = searchParams.has('code')
    ? Number(searchParams.get('code'))
    : NaN

  const configuration = searchParams.has('configuration')
    ? decodeURIComponent(searchParams.get('configuration').trim())
    : null

  const width = searchParams.has('width')
    ? Number(searchParams.get('width'))
    : NaN

  const height = searchParams.has('height')
    ? Number(searchParams.get('height'))
    : NaN

  const quality = searchParams.has('quality')
    ? Number(searchParams.get('quality'))
    : NaN

  if (!isNaN(code)) setCode(code)
  if (configuration) setConfiguration(normalise(configuration))
  if (!isNaN(width)) setWidth(width)
  if (!isNaN(height)) setHeight(height)
  if (!isNaN(quality)) setQuality(quality)
}

export function setRenderDataToURLSearchParams () {
  log('setRenderDataToURLSearchParams')

  const href = getWindowLocationHref()

  const was = new URL(href)
  const now = new URL(href)

  const {
    searchParams: WAS
  } = was

  const {
    searchParams: NOW
  } = now

  const code = hasCode()
    ? getCode()
    : NaN

  const configuration = hasConfiguration()
    ? getConfiguration()
    : null

  const width = hasWidth()
    ? getWidth()
    : NaN

  const height = hasHeight()
    ? getHeight()
    : NaN

  const quality = hasQuality()
    ? getQuality()
    : NaN

  if (!isNaN(code)) NOW.set('code', code)
  if (configuration) NOW.set('configuration', encodeURIComponent(normalise(configuration)))
  if (!isNaN(width)) NOW.set('width', width)
  if (!isNaN(height)) NOW.set('height', height)
  if (!isNaN(quality)) NOW.set('quality', quality)

  const hasChanged = (
    Array.from(WAS.entries())
      .some(([key, value]) => NOW.get(key) !== value) ||
    Array.from(NOW.entries())
      .some(([key, value]) => WAS.get(key) !== value)
  )

  if (hasChanged) global.history.pushState({ configuration }, 'Configuration', now)
}

/**
 *  Reconcile
 */

function preFlight () {
  log('preFlight')

  const configuration = getConfigurationFromButtonObjects()
  const backendConfiguration = getBackendConfigurationFromButtonObjects()

  if (getConfiguration() !== configuration) setConfiguration(configuration)
  if (getBackendConfiguration() !== backendConfiguration) setBackendConfiguration(backendConfiguration)
}

// Next camera
function nextCamera () {
  log('nextCamera')

  const camera = getCamera()

  setCamera((camera === (getCamerasFor(getConfiguration()).length - 1)) ? 0 : camera + 1)

  serverRequest()
}

// Prev camera
function prevCamera () {
  log('prevCamera')

  const camera = getCamera()

  setCamera((camera === 0) ? (getCamerasFor(getConfiguration()).length - 1) : camera - 1)

  serverRequest()
}

function glOrbit (deltaX, deltaY) {
  if (hasWebGLCamera()) {
    const webGLCamera = getWebGLCamera()

    // Calculate values
    webGLCamera.DefaultAngle.Y += deltaX * multiplier
    webGLCamera.DefaultAngle.X = Math.max(-90, Math.min(90, webGLCamera.DefaultAngle.X + deltaY * multiplier))

    // Clamp values
    if (webGLCamera.ClampY.Active) {
      webGLCamera.DefaultAngle.Y = clamp(webGLCamera.DefaultAngle.Y, webGLCamera.ClampY.In, webGLCamera.ClampY.Out)
    }
    if (webGLCamera.ClampX.Active) {
      webGLCamera.DefaultAngle.X = clamp(webGLCamera.DefaultAngle.X, webGLCamera.ClampX.In, webGLCamera.ClampX.Out)
    }
  }
}

function glZoom (deltaY) {
  if (hasWebGLCamera()) {
    const webGLCamera = getWebGLCamera()

    if (webGLCamera.Mode === 'exterior') {
    // Calculate values
      if (deltaY < 0) {
        webGLCamera.Position.Z += multiplier * devicePixelRatio * Math.abs(deltaY) * 10
      } else if (deltaY > 0) {
        webGLCamera.Position.Z -= multiplier * devicePixelRatio * Math.abs(deltaY) * 10
      }

      // Clamp values
      if (webGLCamera.ClampZ.Active) {
        webGLCamera.Position.Z = clamp(webGLCamera.Position.Z, webGLCamera.ClampZ.In, webGLCamera.ClampZ.Out)
      }
    } else if (webGLCamera.Mode === 'interior') {
    // Calculate values
      webGLCamera.Fov += deltaY / scrollMultiplier / 2

      // Clamp values
      if (webGLCamera.ClampZ.Active) {
        webGLCamera.Fov = clamp(webGLCamera.Fov, webGLCamera.ClampZ.In, webGLCamera.ClampZ.Out)
      }

      // Update LightGLCamera
      updateLightGLCamera()
    }
  }
}

function updateLightGLCamera () {
  // Assign LightGL settings

  if (hasWebGLCamera()) {
    const webGLCamera = getWebGLCamera()

    gl.matrixMode(gl.PROJECTION)
    gl.loadIdentity()
    gl.perspective(webGLCamera.Fov, gl.canvas.width / gl.canvas.height, webGLCamera.Clipping.X, webGLCamera.Clipping.Y)
    gl.matrixMode(gl.MODELVIEW)
  }
}

function updateWebGLCamera (id, check) {
  if (hasWebGLCameras()) {
    const webGLCameras = getWebGLCameras()

    // Check if ID is not from a camera button
    if (check) {
      let isWebGLCameraID = false
      for (const webGLCamera of webGLCameras) {
        if ('+' + webGLCamera.ID === id) {
          isWebGLCameraID = true
          break
        }
      }

      // Escape
      if (!isWebGLCameraID) return
    }

    // Update Camera from Configuration
    for (const webGLCamera of webGLCameras) {
      if ('+' + webGLCamera.ID === id) {
        setWebGLCamera(webGLCamera)
        break
      }
    }

    // Update LightGLCamera
    updateLightGLCamera()
  }
}

// Communication with server
function zoomSpeed (z) {
  socket.emit('orbitSpeed', { X: 0, Y: 0, Z: z * 0.05 })
}

function orbitSpeed (x, y) {
  socket.emit('orbitSpeed', { X: x, Y: y, Z: 0 })
}

function findCurrentCamera (configuration = getConfiguration(), backendConfiguration = getBackendConfiguration()) {
  log('findCurrentCamera')

  let camera

  if (isPageModeWeb() || isPageModePreRender()) {
    camera = getCurrentCamera(configuration)
  } else if (isPageModeDealership() || isPageModePlus() || isPageModeVR() || isPageModeWebGL()) {
    if (hasWebGLCameras()) {
      const webGLCameras = getWebGLCameras()

      camera = webGLCameras.find(({ ID }) => {
        const webGLCameraID = '+'.concat(ID)

        return (
          backendConfiguration.includes(webGLCameraID)
        )
      })
    }
  }

  return camera
}

function requestImage () {
  /**
   *  log('requestImage')
   */

  preFlight()

  if (hasWebGLCamera()) {
    const webGLCamera = getWebGLCamera()

    if (hasWebGLCameras()) {
      const webGLCameras = getWebGLCameras()

      if (webGLCamera.Mode === 'exterior') {
        webGLMessage = {
          CameraMode: webGLCamera.Mode,
          Fov: webGLCamera.Fov,
          DofFocusDistance: webGLCamera.DofFocusDistance,
          DofFocalLength: webGLCamera.DofFocalLength,
          Position: {
            X: webGLCamera.Position.X * 0.01,
            Y: webGLCamera.Position.Y * -0.01,
            Z: webGLCamera.Position.Z * 0.01
          },
          Rotation: {
            X: webGLCamera.DefaultAngle.X,
            Y: webGLCamera.DefaultAngle.Y % 360,
            Z: 0
          },
          Index: webGLCameras.indexOf(webGLCamera)
        }
      } else if (webGLCamera.Mode === 'interior') {
        webGLMessage = {
          CameraMode: webGLCamera.Mode,
          Fov: webGLCamera.Fov,
          DofFocusDistance: webGLCamera.DofFocusDistance,
          DofFocalLength: webGLCamera.DofFocalLength,
          Position: {
            X: webGLCamera.Position.Z * -0.01,
            Y: webGLCamera.Position.Y * -0.01,
            Z: webGLCamera.Position.X * -0.01
          },
          Rotation: {
            X: webGLCamera.DefaultAngle.X,
            Y: webGLCamera.DefaultAngle.Y % 360,
            Z: 0
          },
          Index: webGLCameras.indexOf(webGLCamera)
        }
      }

      serverRequest()
    }
  }
}

function requestImages () {
  /**
   *  log('requestImages')
   */

  preFlight()

  if (hasReviewCameras()) {
    const reviewCameras = getReviewCameras()

    imagesToLoad = reviewCameras.length

    /**
     *  Always reset the timeout if there is one
     */
    resetLoadingTimeout()

    /**
     *  Images returned instantly from the server cache don't need a loader,
     *  so put this slightly into the future
     */
    createShowLoadingTimeout()

    const socketID = socket.id
    const configuration = getConfiguration()
    const backendConfiguration = getBackendConfiguration()

    reviewCameras
      .forEach((reviewCamera) => {
        reviewCamera.SocketID = socketID
        reviewCamera.Configuration = configuration
        reviewCamera.BackendConfiguration = backendConfiguration
        reviewCamera.ImageName = createCameraImageName(reviewCamera)
      })

    socket.emit('hqImageRequest', reviewCameras)
  }
}

function createImageRequest (camera) {
  log('createImageRequest')

  const configuration = getConfiguration()

  const backendConfiguration = getBackendConfiguration()

  camera = (
    isPageModeWeb() || isPageModePreRender()
      ? camera
      : isPageModeWebGL()
        ? findCurrentCamera(configuration, backendConfiguration)
        : null
  )

  if (!camera) return null

  let request

  const code = hasCode()
    ? getCode()
    : 0

  const width = hasWidth()
    ? getWidth()
    : DEFAULT_WIDTH

  const height = hasHeight()
    ? getHeight()
    : DEFAULT_HEIGHT

  const quality = hasQuality()
    ? getQuality()
    : undefined // not null, zero, or false

  const {
    GlobalMultiplier
  } = camera

  if (!webGLMessage) {
    const {
      Fov = DEFAULT_FOV,
      DofFocusDistance,
      DofFocalLength,
      DofNearOffset,
      Position,
      Direction
    } = camera

    request = {
      Type: getPageMode(),
      SocketID: socket.id,
      Code: code,
      Configuration: configuration,
      BackendConfiguration: backendConfiguration,
      Width: width,
      Height: height,
      Quality: quality,
      Fov,
      DofFocusDistance,
      DofFocalLength,
      DofNearOffset,
      Position,
      Direction,
      GlobalMultiplier
    }

    const {
      VerticalFov = Fov,
      RotationType,
      RotationQ,
      CameraRule,
      Width,
      Height
    } = camera

    if (RotationType === 'free') {
      request.RotationType = RotationType
      request.RotationQ = RotationQ
    }

    // Vertical field of view
    if (isVertical()) {
      request.Fov = VerticalFov
    }

    // Add camera Rules
    if (CameraRule) {
      request.BackendConfiguration += CameraRule
    }

    // Update Width
    if (Width) {
      request.Width = Width
    }

    // Update Height
    if (Height) {
      request.Height = Height
    }
  } else {
    const {
      Fov,
      DofFocalLength,
      DofNearOffset,
      Index,
      Rotation,
      Position,
      CameraMode
    } = webGLMessage

    request = {
      Type: getPageMode(),
      SocketID: socket.id,
      Code: code,
      Configuration: configuration,
      BackendConfiguration: backendConfiguration,
      Width: width,
      Height: height,
      Quality: quality,
      Fov,
      DofFocalLength,
      DofNearOffset,
      Index,
      Rotation,
      Position,
      CameraMode,
      GlobalMultiplier
    }

    const {
      CameraRule,
      Width,
      Height
    } = getWebGLCamera()

    // Add camera Rules
    if (CameraRule) {
      request.BackendConfiguration += CameraRule
    }

    // Update Width
    if (Width) {
      request.Width = Width
    }

    // Update Height
    if (Height) {
      request.Height = Height
    }
  }

  return request
}

/**
 *  Socket
 */

export const getSocketProtocol = () => isSecure() ? 'wss' : 'ws'

export const getSocketHostname = () => hasSocket() ? getSocket() : getSocketFrom(defaultData)

export const hasSocketHostname = () => Boolean(hasSocket() ? getSocket() : getSocketFrom(defaultData))

function requestImageForDefaultCamera () {
  log('requestImageForDefaultCamera')

  const imageRequest = createImageRequest(getDefaultCamera(getConfiguration()))
  if (imageRequest) {
    const imageName = createCameraImageName(imageRequest)

    imageRequest.ImageName = imageName

    DEFAULT_CAMERA_IMAGE_NAME_CACHE.add(imageName)

    /**
     *  Always reset the timeout if there is one
     */
    resetLoadingTimeout()

    /**
     *  Show the loader!
     */
    createShowLoadingTimeout()

    socket.emit('imageRequest', imageRequest)
  } else {
    const {
      requestAnimationFrame = function requestAnimationFrame () {
        log('`requestAnimationFrame` is not available')
      }
    } = global

    requestAnimationFrame(() => {
      const backgroundImage = `url(${DEFAULT_CAMERA_IMAGE_SRC})`

      const img1 = document.getElementById('img1')
      if (img1) img1.style.backgroundImage = backgroundImage

      const img2 = document.getElementById('img2')
      if (img2) img2.style.backgroundImage = backgroundImage
    })
  }
}

function requestImageForCurrentCamera () {
  log('requestImageForCurrentCamera')

  const imageRequest = createImageRequest(getCurrentCamera(getConfiguration()))
  if (imageRequest) {
    const imageName = createCameraImageName(imageRequest)

    imageRequest.ImageName = imageName

    CURRENT_CAMERA_IMAGE_NAME_CACHE.add(imageName)

    /**
     *  Always reset the timeout if there is one
     */
    resetLoadingTimeout()

    /**
     *  Show the loader!
     */
    createShowLoadingTimeout()

    socket.emit('imageRequest', imageRequest)
  } else {
    const {
      requestAnimationFrame = function requestAnimationFrame () {
        log('`requestAnimationFrame` is not available')
      }
    } = global

    requestAnimationFrame(() => {
      const backgroundImage = `url(${DEFAULT_CAMERA_IMAGE_SRC})`

      const img1 = document.getElementById('img1')
      if (img1) img1.style.backgroundImage = backgroundImage

      const img2 = document.getElementById('img2')
      if (img2) img2.style.backgroundImage = backgroundImage
    })
  }
}

function requestUpdateConfiguration () {
  log('requestUpdateConfiguration')

  const configuration = getConfiguration()
  const backendConfiguration = getBackendConfiguration()

  const {
    GlobalMultiplier
  } = findCurrentCamera(configuration, backendConfiguration)

  const updateConfiguration = {
    Configuration: configuration,
    BackendConfiguration: backendConfiguration,
    GlobalMultiplier
  }

  socket.emit('updateConfiguration', updateConfiguration)
}

function serverRequest () {
  log('serverRequest')

  preFlight()

  if (isPageModeWeb() || isPageModePreRender() || isPageModeWebGL()) {
    requestImageForCurrentCamera()
  } else if (isPageModeDealership() || isPageModePlus() || isPageModeVR()) {
    requestUpdateConfiguration()
  }
}

function handleConnect () {
  log('handleConnect')

  /**
   *  Always reset the timeout if there is one
   */
  resetLoadingTimeout()

  /**
   *  Hide the loader!
   */
  createHideLoadingTimeout()

  // Hide loading or request image
  if (isPageModeWeb() || isPageModePreRender()) {
    requestImageForCurrentCamera()
  } else if (isPageModeWebGL()) {
    requestImage()
  } else if (isPageModeReview()) {
    requestImages()
  } else if (isPageModeDealership() || isPageModePlus() || isPageModeVR()) {
    requestUpdateConfiguration()
  }
}

function handleImageResponse (updateData) {
  log('handleImageResponse')

  /**
   *  Always reset the timeout if there is one
   */
  resetLoadingTimeout()

  /**
   *  This is only relevant if the loader is showing (but it won't hurt)
   */
  createHideLoadingTimeout()

  if (isPageModeWeb() || isPageModePreRender() || isPageModeWebGL()) {
    const {
      ImageName,
      Extension = '.jpg'
    } = updateData

    if (ImageName && Extension) {
      const hostname = getSocketHostname() || getWindowLocationHostname()
      const hasPort = !( // NOT
        isSecure()
          ? isDefaultSecurePort()
          : isDefaultPort()
      )

      const fileHost = hasPort ? `//${hostname}:${getWindowLocationPort()}/api/images` : `//${hostname}/api/images`
      const fileName = ImageName + Extension
      const uri = `${fileHost}/${fileName}`

      if (DEFAULT_CAMERA_IMAGE_NAME_CACHE.has(ImageName)) {
        DEFAULT_CAMERA_IMAGE_CACHE.src = uri
      }

      if (CURRENT_CAMERA_IMAGE_NAME_CACHE.has(ImageName)) {
        CURRENT_CAMERA_IMAGE_CACHE.src = uri
      }
    }
  } else if (isPageModeReview()) {
    const img = document.getElementById(updateData.Id)
    if (img) {
      const {
        ImageName,
        Extension
      } = updateData

      if (ImageName && Extension) {
        const {
          requestAnimationFrame = function requestAnimationFrame () {
            log('`requestAnimationFrame` is not available')
          }
        } = global

        requestAnimationFrame(() => {
          const hostname = getSocketHostname() || getWindowLocationHostname()
          const hasPort = !( // NOT
            isSecure()
              ? isDefaultSecurePort()
              : isDefaultPort()
          )

          const fileHost = hasPort ? `//${hostname}:${getWindowLocationPort()}/api/images` : `//${hostname}/api/images`
          const fileName = ImageName + Extension
          const uri = `${fileHost}/${fileName}`

          img.src = uri
          img.parentElement.href = uri
        })
      }
    }
  }
}

function handleUpdateConfigurationFromServer (updateData) {
  log('handleUpdateConfigurationFromServer')

  /**
   *  Always reset the timeout if there is one
   */
  resetLoadingTimeout()

  /**
   *  This is only relevant if the loader is showing (but it won't hurt)
   */
  createHideLoadingTimeout()

  if (isPageModeDealership() || isPageModePlus() || isPageModeVR()) {
    if (
      hasConfigurationIn(updateData) &&
      hasBackendConfigurationIn(updateData)) {
      const configuration = getConfiguration()
      const backendConfiguration = getBackendConfiguration()

      const CONFIGURATION = normalise(getConfigurationFrom(updateData))
      const BACKENDCONFIGURATION = normalise(getBackendConfigurationFrom(updateData))

      const hasChanged = (
        configuration !== CONFIGURATION ||
        backendConfiguration !== BACKENDCONFIGURATION)

      if (hasChanged) {
        setConfiguration(CONFIGURATION)
        setBackendConfiguration(BACKENDCONFIGURATION)

        resetAllButtonObjectsChanged()

        updateAllButtonObjectsSelected()

        updateAllButtonObjectsDisabled()

        updateAllButtonObjectsChanged()

        updateAllButtonObjectsPrices()

        renderAllButtonObjects()

        render()

        change()
      }
    }
  }
}

function handleResetDealershipToUnity (updateData) {
  log('handleResetDealershipToUnity')

  /**
   *  Always reset the timeout if there is one
   */
  resetLoadingTimeout()

  /**
   *  This is only relevant if the loader is showing (but it won't hurt)
   */
  createHideLoadingTimeout()

  if (isPageModeDealership() || isPageModePlus() || isPageModeVR()) {
    if (
      hasConfigurationIn(updateData) &&
      hasBackendConfigurationIn(updateData)) {
      const configuration = getConfiguration()
      const backendConfiguration = getBackendConfiguration()

      const CONFIGURATION = normalise(getConfigurationFrom(updateData))
      const BACKENDCONFIGURATION = normalise(getBackendConfigurationFrom(updateData))

      const hasChanged = (
        configuration !== CONFIGURATION ||
        backendConfiguration !== BACKENDCONFIGURATION)

      if (hasChanged) {
        setConfiguration(CONFIGURATION)
        setBackendConfiguration(BACKENDCONFIGURATION)

        resetAllButtonObjectsChanged()

        updateAllButtonObjectsSelected()

        updateAllButtonObjectsDisabled()

        updateAllButtonObjectsChanged()

        updateAllButtonObjectsPrices()

        renderAllButtonObjects()

        render()

        change()
      }
    }
  }
}

function getSocketUri () {
  const protocol = getSocketProtocol()

  if (hasSocketHostname()) {
    return `${protocol}://${getSocketHostname()}?token=visitor`
  } else {
    const hostname = getWindowLocationHostname()
    const hasPort = !( // NOT
      isSecure()
        ? isDefaultSecurePort()
        : isDefaultPort()
    )

    return (
      hasPort
        ? `${protocol}://${hostname}:${getWindowLocationPort()}?token=visitor`
        : `${protocol}://${hostname}?token=visitor`
    )
  }
}

function connect () {
  log('connect')

  const uri = getSocketUri()
  const parameters = {
    secure: isSecure(),
    'sync disconnect on unload': true,
    withCredentials: true
  }

  log({ uri, parameters })

  /**
   *  Create a socket
   */
  socket = io(uri, parameters)

  socket.on('connect', handleConnect)

  // Web / WebGl / Prerender / Review
  socket.on('imageResponse', handleImageResponse)

  // Dealership
  socket.on('updateConfigurationFromServer', handleUpdateConfigurationFromServer)

  // Dealership
  socket.on('resetDealershipToUnity', handleResetDealershipToUnity)
}

/**
 *  Fetch
 */

// Server
function handleSettingsResponse (responseData = {}) {
  log('handleSettingsResponse')

  Object.assign(defaultData, responseData)

  // Assign `PageMode` when `PageMode` is not defined
  if (!hasPageMode()) setPageMode(getPageModeFrom(defaultData).toLocaleLowerCase())

  /**
   *  Transform the `Prices` multi-dimensional array into the `ButtonObjects` collection
   */
  if (hasPricesIn(defaultData)) {
    const Prices = getPricesFrom(defaultData)

    /**
     *  Assign `Prices` to Render Data
     */
    setPrices(Prices)

    /**
     *  Destructure the first element to `Titles`
     *  and assign all other elements to `Values`
     */
    const [
      Titles = [],
      ...Values
    ] = Prices

    /**
     *  `PriceColumnRules` with slice on `Titles` from position 20 (to n)
     */
    const priceColumnRules = Titles.slice(20)

    /**
     *  Assign `PriceColumnRules` to Render Data
     */
    setPriceColumnRules(priceColumnRules)

    const ButtonObjects = Values
      /**
       *  Create `ButtonObjects`
       */
      .map(transformToButtonObject)
      /**
       *  Remove `ButtonObjects` where `Visibility` is false
       */
      .filter(isVisible)

    /**
     *  Assign `ButtonObjects` to Render Data
     */
    setButtonObjects(ButtonObjects)
  }

  if (hasCamerasIn(defaultData)) setCameras(getCamerasFrom(defaultData))

  if (hasWebGLCamerasIn(defaultData)) setWebGLCameras(getWebGLCamerasFrom(defaultData))

  if (hasReviewCamerasIn(defaultData)) setReviewCameras(getReviewCamerasFrom(defaultData))

  if (hasWebGLCameras()) {
    const webGLCameras = getWebGLCameras()

    setWebGLCamera(webGLCameras.find(getFindWebGLCameraForButtonObjects(getAllButtonObjects())))
  }

  /**
   *  There is no model in this application, nor store, nor state
   *
   *  The "Source of Truth" is the `Configuration` (which is a string of delimited parameters)
   *
   *  Our interface is described by a collection of `ButtonObjects`. Each `buttonObject` has an
   *  <input /> element which can be `selected` or `disabled`. Obviously, that follows:
   *
   *    -----------------------
   *    | Selected | Disabled |
   *    -----------------------
   *    | +        | +        |
   *    | +        | -        |
   *    | -        | +        |
   *    | -        | -        |
   *    -----------------------
   *
   *  `Configuration` tells us whether a `buttonObject` should be `selected`
   *
   *  Iterating over the `ButtonObjects` collection and testing each `buttonObject.VisibilityRule` against `Configuration`
   *  tells us whether that `buttonObject` should be `disabled`
   *
   *  The interface can change in either of these ways:
   *
   *    1. A `Configuration` is provided by the server in the page
   *    2. A `Configuration` is parsed from the URL of the page
   *    3. A `Configuration` is provided by the server on the socket
   *        Or
   *    4. An <input /> element change event is initiated by the user
   *
   *  In any of 1 - 3, the `Configuration` supercedes the current state and changes the <input /> elements
   *
   *  In 4, the `Configuration` is derived from the <input /> elements and supercedes the current state
   */

  /**
   *  If Render Data has `Configuration` then we should use it
   */
  if (hasConfiguration()) {
    /**
     *  Use `renderData.Configuration`
     */
    setConfiguration(normalise(getConfiguration()))
    /**
     *  If `Configuration` was parsed from the URL then `BackendConfiguration` may be `null` so use `defaultData.BackendConfiguration`
     */
    setBackendConfiguration(normalise(hasBackendConfiguration() ? getBackendConfiguration() : getBackendConfigurationFrom(defaultData)))
  } else {
    /**
     *  Otherwise, use `defaultData.Configuration`
     */
    setConfiguration(normalise(getConfigurationFrom(defaultData)))
    /**
     *  Use `defaultData.BackendConfigurationConfiguration`
     */
    setBackendConfiguration(normalise(getBackendConfigurationFrom(defaultData)))
  }

  /**
   *  Initialise the user interface
   */
  initialise()

  registerEventListeners()

  configureViewport()

  if (isPageModeWebGL()) initialiseLightGL()

  /**
   *  Finally, connect to the Socket
   *
   *  We do this last so that Socket events don't squish initialisation
   */
  connect()
}

function requestSettings () {
  log('requestSettings')

  const origin = getWindowLocationOrigin()

  // Fix settingsMode for the web versions
  const regionCode = hasRegionCode() ? getRegionCode() : 'en'
  let settingsMode = getPageMode().toLocaleLowerCase()
  if (settingsMode === PRERENDER || settingsMode === WEBGL || settingsMode === REVIEW) settingsMode = WEB

  return (
    fetch(`${origin}/api/getsettings/${regionCode}/${settingsMode}`)
      .then((response) => response.json())
      .then(handleSettingsResponse)
  )
}

/**
 *  User interface
 */

export function changeButtonObject (buttonObject) {
  log('changeButtonObject')

  /**
   *  Add or remove the `selected` class before anything else is executed
   *
   *  This is presentational (for user feedback) but will not be contradicted
   *  in subsequent execution
   */
  updateUIElementContainerClassList(buttonObject, { selected: isSelected(buttonObject) })

  /**
   *  Let's go to work
   */
  let configuration = getConfigurationFromButtonObjects()

  if (hasButtonObjectFrontEndRule(buttonObject)) {
    const frontEndRule = getButtonObjectFrontEndRule(buttonObject)

    if (hasDefaultConfiguration(frontEndRule, configuration)) {
      const defaultConfiguration = getDefaultConfiguration(frontEndRule, configuration)

      if (defaultConfiguration) {
        const CONFIGURATION = normalise(defaultConfiguration)

        if (configuration !== CONFIGURATION) configuration = CONFIGURATION
      }
    }
  }

  setConfiguration(configuration)

  updateAllButtonObjectsSelected()

  updateAllButtonObjectsDisabled()

  setBackendConfiguration(getBackendConfigurationFromButtonObjects())

  updateAllButtonObjectsChanged()

  updateAllButtonObjectsPrices()

  renderAllButtonObjects()

  render()

  change()

  // Best camera finder

  if (isPageModeWeb() || isPageModePreRender()) {
    if (hasButtonObjectCameraIndices(buttonObject)) {
      const cameraIndices = getButtonObjectCameraIndices(buttonObject)

      if (hasButtonObjectCamera(buttonObject)) {
        const camera = getButtonObjectCamera(buttonObject)

        if (!cameraIndices.includes(camera)) {
          const [
            camera
          ] = cameraIndices

          setCamera(camera)
        }
      }
    }
  } else if (isPageModeDealership() || isPageModePlus() || isPageModeVR() || isPageModeWebGL()) {
    if (hasButtonObjectWebGLCamIDs(buttonObject)) {
      const webGLCamIDs = getButtonObjectWebGLCamIDs(buttonObject)

      const {
        ID
      } = getWebGLCamera()

      if (!webGLCamIDs.includes(ID)) {
        const [
          webGLCamID
        ] = webGLCamIDs

        const was = '+'.concat(ID)
        const now = '+'.concat(webGLCamID)

        setConfiguration(normalise(getConfiguration().replace(was, now)))
        setBackendConfiguration(normalise(getBackendConfiguration().replace(was, now)))

        const webGLCamera = document.getElementById(now)
        if (webGLCamera) webGLCamera.checked = true
      }
    }
  }

  if (isPageModeWebGL()) {
    if (hasButtonObjectUIElement(buttonObject)) {
      const {
        id
      } = getButtonObjectUIElement(buttonObject)

      updateWebGLCamera(id, true)
    }
  }

  if (isPageModeWebGL() || isPageModeReview()) {
    requestImages()
  } else {
    serverRequest()
  }
}

export function getHandleChangeButtonObjectForTypeDropdown ({ ButtonObjects = [] }) {
  /**
   *  log('getHandleChangeButtonObjectForTypeDropdown')
   */

  function findButtonObjectForEventTarget ({ target }) {
    /**
     *  log('findButtonObjectForEventTarget')
     */

    return (
      ButtonObjects.find((buttonObject) => target === getButtonObjectUIElement(buttonObject))
    )
  }

  return function handleChangeButtonObjectForTypeDropdown (event) {
    /**
     *  log('handleChangeButtonObjectForTypeDropdown')
     */

    return (
      changeButtonObject(findButtonObjectForEventTarget(event))
    )
  }
}

export function getHandleChangeButtonObject (buttonObject) {
  /**
   *  log('getHandleChangeButtonObject')
   */

  return function handleChangeButtonObject () {
    /**
     *  log('handleChangeButtonObject')
     */

    return (
      changeButtonObject(buttonObject)
    )
  }
}

export function initialise () {
  log('initialise')

  /**
   *  1. Create the UI elements (neither `selected` nor `disabled`)
   */
  getAllButtonObjects()
    .forEach((buttonObject, i) => {
      if (buttonObject.AutoGenerate) {
        renderUIElementContainer(buttonObject, i)
      } else {
        updateUIElementContainer(buttonObject, i)
      }
    })

  /**
   *  2. Ensure elements are `selected`
   */
  updateAllButtonObjectsSelected()

  /**
   *  3. Ensure elements are `disabled`
   */
  updateAllButtonObjectsDisabled()

  /**
   *  4. Derive `BackendConfiguration` from <input /> elements
   */
  setBackendConfiguration(getBackendConfigurationFromButtonObjects())

  /**
   *  5. Compute and assign "Changed" state
   */
  updateAllButtonObjectsChanged()

  /**
   *  6. Ensure prices are calculated
   */
  updateAllButtonObjectsPrices()

  /**
   *  7. Render (Paint) all elements
   */
  renderAllButtonObjects()

  /**
   *  8. Render (Paint) other furniture
   */
  render()

  /**
   *  9. Change (Event)
   */
  change()
}

export function initialiseLightGL () {
  log('initialiseLightGL')

  // Create light.gl object
  gl = GL.create()

  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  requestAnimationFrame(() => {
  // Add LightGL to the HTML
    document.getElementById('gameContainer')
      .appendChild(gl.canvas)
  })

  objectFitCover()

  global.addEventListener('resize', objectFitCover)

  // Load meshes
  e_wirecolor_mesh = GL.Mesh.load(e_wireframe) // eslint-disable-line camelcase
  e_wirecolor_blocker_mesh = GL.Mesh.load(e_wireframe_blocker) // eslint-disable-line camelcase
  i_wirecolor_mesh = GL.Mesh.load(i_wireframe) // eslint-disable-line camelcase
  i_wirecolor_blocker_mesh = GL.Mesh.load(i_wireframe_blocker) // eslint-disable-line camelcase

  // Shaders
  const blackShader = new GL.Shader(`
    void main() {
      gl_Position = gl_ModelViewProjectionMatrix * gl_Vertex;
    }
  `, `
    void main() {
      gl_FragColor = vec4(.97, .97, .97, 1.0);
    }
  `)

  const goldShader = new GL.Shader(`
    void main() {
      gl_Position = gl_ModelViewProjectionMatrix * gl_Vertex;
    }
  `, `
    void main() {
      gl_FragColor = vec4(0.0, 0.0, 0.0, 1.0);
    }
  `)

  // Canvas setup
  gl.canvas.width = hasWidth() ? getWidth() / 2 : DEFAULT_WIDTH
  gl.canvas.height = hasHeight() ? getHeight() / 2 : DEFAULT_HEIGHT
  gl.viewport(0, 0, gl.canvas.width, gl.canvas.height)

  if (hasWebGLCamera()) {
    const webGLCamera = getWebGLCamera()

    // Get default ID and set it
    updateWebGLCamera('+' + webGLCamera.ID)
  }

  // Update callback
  // gl.onupdate = function (seconds) {
  //     log(seconds);
  //     angle += 45 * seconds;
  // };

  // Draw callback
  gl.ondraw = function () {
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT)
    gl.loadIdentity()

    if (hasWebGLCamera()) {
      const webGLCamera = getWebGLCamera()

      if (webGLCamera.Mode === 'exterior') {
        gl.translate(webGLCamera.Position.X, webGLCamera.Position.Y, webGLCamera.Position.Z)
        gl.rotate(webGLCamera.DefaultAngle.X, 1, 0, 0)
        gl.rotate(webGLCamera.DefaultAngle.Y, 0, 1, 0)

        // Draw exterior ribbons
        blackShader.draw(e_wirecolor_blocker_mesh)
        goldShader.draw(e_wirecolor_mesh)
      } else if (webGLCamera.Mode === 'interior') {
        gl.rotate(webGLCamera.DefaultAngle.X, 1, 0, 0)
        gl.rotate(webGLCamera.DefaultAngle.Y, 0, 1, 0)
        gl.translate(webGLCamera.Position.Z, webGLCamera.Position.Y, -webGLCamera.Position.X)

        // Draw interior ribbons
        blackShader.draw(i_wirecolor_blocker_mesh)
        goldShader.draw(i_wirecolor_mesh)
      }
    }
  }

  // Start
  gl.animate()
  gl.clearColor(0.97, 0.97, 0.97, 1)
  gl.enable(gl.CULL_FACE)
  gl.enable(gl.DEPTH_TEST)
}

export function resize () {
  log('resize')

  document.getElementById('renderapp')
    .dispatchEvent(new Event('resize'))
}

export function change () {
  log('change')

  document.getElementById('renderapp')
    .dispatchEvent(new Event('change'))
}

export function getHandleChangeForGroupModels (buttonObject) {
  /**
   *  log('getHandleChangeForGroupModels')
   */

  return function handleChangeForGroupModels () {
    /**
     *  log('handleChangeForGroupModels')
     */

    resetAllButtonObjectsChanged()

    buttonObject.Changed = true
  }
}

export function getHandleChangeForGroupPack (buttonObject) {
  /**
   *  log('getHandleChangeForGroupPack')
   */

  const groups = getGroupsFromButtonObjects()
    .filter((group) => group !== MODELS)

  return function handleChangeForGroupPack () {
    /**
     *  log('handleChangeForGroupPack')
     */

    groups
      .forEach(resetButtonObjectsChangedForGroup)

    buttonObject.Changed = true
  }
}

export function getHandleChangeForGroup (buttonObject) {
  /**
   *  log('getHandleChangeForGroup')
   */

  const group = getButtonObjectGroup(buttonObject)

  return function handleChangeForGroup () {
    /**
     *  log('handleChangeForGroup')
     */

    resetButtonObjectsChangedForGroup(group)

    buttonObject.Changed = true
  }
}

export function getHandleChangeForPairWith (buttonObject) {
  /**
   *  log('getHandleChangeForPairWith')
   */

  const pairWithRule = getButtonObjectPairWithRule(buttonObject)

  const buttonObjects = getAllButtonObjects()
    .filter((buttonObject) => pairWithRule === getButtonObjectFrontEndRule(buttonObject))

  return function handleChangeForPairWith () {
    /**
     *  log('handleChangeForPairWith')
     */

    buttonObjects
      .filter(isEnabled)
      .forEach((buttonObject) => {
        /**
         *  1.
         */
        updateUIElementSelected(buttonObject)

        /**
         *  2.
         */
        resetButtonObjectsChangedForGroup(getButtonObjectGroup(buttonObject))

        buttonObject.Changed = true
      })

    setConfiguration(getConfigurationFromButtonObjects())
    setBackendConfiguration(getBackendConfigurationFromButtonObjects())

    updateAllButtonObjectsPrices()

    renderButtonObjects(buttonObjects)

    render()

    change()

    serverRequest()
  }
}

export function handleDefaultCameraImageCacheLoad () {
  log('handleDefaultCameraImageCacheLoad')

  const {
    alt,
    src
  } = DEFAULT_CAMERA_IMAGE_CACHE

  if (src) {
    const {
      requestAnimationFrame = function requestAnimationFrame () {
        log('`requestAnimationFrame` is not available')
      }
    } = global

    requestAnimationFrame(() => {
      const ALT = (alt || DEFAULT_CAMERA_IMAGE_ALT).trim()
      const SRC = (src || DEFAULT_CAMERA_IMAGE_SRC).trim()

      document.querySelectorAll('#renderapp .options-preview img')
        .forEach((img) => {
          img.alt = ALT
          img.src = SRC
          img.className = 'show'
        })
    })
  }
}

export function handleCurrentCameraImageCacheLoad () {
  log('handleCurrentCameraImageCacheLoad')

  /**
   *  An image `load` event is firing so the server/Socket work is done :)
   */

  if (isPageModeWeb() || isPageModePreRender()) {
    const {
      src
    } = CURRENT_CAMERA_IMAGE_CACHE

    const {
      requestAnimationFrame = function requestAnimationFrame () {
        log('`requestAnimationFrame` is not available')
      }
    } = global

    requestAnimationFrame(() => {
      const img1 = document.getElementById('img1')
      const img2 = document.getElementById('img2')

      img1.style.backgroundImage = img2.style.backgroundImage || ''
      img2.className = 'hide'
      img2.style.backgroundImage = `url(${src})`
      img2.className = 'show' // 'fade-in'
    })
  } else if (isPageModeWebGL()) {
    const {
      src
    } = CURRENT_CAMERA_IMAGE_CACHE

    const {
      requestAnimationFrame = function requestAnimationFrame () {
        log('`requestAnimationFrame` is not available')
      }
    } = global

    requestAnimationFrame(() => {
      const {
        classList
      } = document.getElementById('gameContainer')

      const img1 = document.getElementById('img1')
      const img2 = document.getElementById('img2')

      if (classList.contains('canvasHide')) {
        img1.style.backgroundImage = img2.style.backgroundImage || ''
        img2.className = 'hide'
        img2.style.backgroundImage = `url(${src})`
        img2.className = 'show' // 'fade-in'
      } else {
        img1.className = 'hide'
        img2.style.backgroundImage = `url(${src})`
        img2.className = 'show'

        /**
         *  Hide the canvas
         */
        hideCanvas()
      }
    })

    setTimeout(() => {
      const pathParts = CURRENT_CAMERA_IMAGE_CACHE.src.split('/')
      socket.emit('deleteImage', pathParts[pathParts.length - 1])
    }, 1000)
  }

  /**
   *  Always reset the timeout if there is one
   */
  resetLoadingTimeout()

  /**
   *  Hide the loader!
   */
  createHideLoadingTimeout()
}

export function handleClickForSendToARetailer () {
  log('handleClickForSendToARetailer')

  const {
    parent = {
      postMessage () {
        log('`postMessage` is not available')
      }
    }
  } = global

  const {
    src // don't default on `src`
  } = CURRENT_CAMERA_IMAGE_CACHE

  const configuration = getConfiguration()
  const imageSrc = (src || DEFAULT_CAMERA_IMAGE_SRC).trim()
  const imageUrl = imageSrc.startsWith('/')
    ? getWindowLocationOrigin().concat(imageSrc)
    : imageSrc

  log(configuration, imageUrl)

  parent.postMessage({
    event: 'sendToRetailer',
    configuration,
    imageUrl
  }, '*')
}

/**
 * Share
 */

export function handleClickForShare () {
  log('handleClickForShare')

  const {
    parent = {
      postMessage () {
        log('`postMessage` is not available')
      }
    }
  } = global

  const {
    src // don't default on `src`
  } = CURRENT_CAMERA_IMAGE_CACHE

  const configuration = getConfiguration()
  const imageSrc = (src || DEFAULT_CAMERA_IMAGE_SRC).trim()
  const imageUrl = imageSrc.startsWith('/')
    ? getWindowLocationOrigin().concat(imageSrc)
    : imageSrc

  log(configuration, imageUrl)

  parent.postMessage({
    event: 'share',
    configuration,
    imageUrl
  }, '*')
}

/**
 *  Reset
 */

export function handleClickForReset () {
  log('handleClickForReset')

  const configuration = getConfiguration()
  const backendConfiguration = getBackendConfiguration()

  const CONFIGURATION = normalise(getConfigurationFrom(defaultData))
  const BACKENDCONFIGURATION = normalise(getBackendConfigurationFrom(defaultData))

  const hasChanged = (
    configuration !== CONFIGURATION ||
    backendConfiguration !== BACKENDCONFIGURATION)

  if (hasChanged) {
    setConfiguration(CONFIGURATION)
    setBackendConfiguration(BACKENDCONFIGURATION)

    resetAllButtonObjectsChanged()

    updateAllButtonObjectsSelected()

    updateAllButtonObjectsDisabled()

    updateAllButtonObjectsChanged()

    updateAllButtonObjectsPrices()

    renderAllButtonObjects()

    render()

    change()

    serverRequest()
  }
}

/**
 *  Print
 */

export function appendImageForPrint () {
  /**
   *  log('appendImageForPrint')
   */

  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  requestAnimationFrame(() => {
    const element = document.createElement('div')
    element.className = 'configuration'
    element
      .appendChild(createCurrentCameraImageFromCache())

    const parentElement = document.getElementById('renderapp')

    parentElement
      .appendChild(element)
  })
}

export function removeImageForPrint () {
  /**
   *  log('removeImageForPrint')
   */

  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  requestAnimationFrame(() => {
    const parentElement = document.getElementById('renderapp')

    parentElement
      .querySelectorAll('.configuration')
      .forEach((element) => {
        parentElement.removeChild(element)
      })
  })
}

export function appendOptionsSummaryClassNameForPrint () {
  /**
   *  log('addOptionsSummaryClassNameForPrint')
   */

  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  requestAnimationFrame(() => {
    const {
      classList
    } = document.body

    classList.remove('print-configuration')
    classList.add('print-options-summary')
  })
}

export function removeOptionsSummaryClassNameForPrint () {
  /**
   *  log('removeOptionsSummaryClassNameForPrint')
   */

  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  requestAnimationFrame(() => {
    const {
      classList
    } = document.body

    classList.remove('print-options-summary')
    classList.add('print-configuration')
  })
}

export function handleClickForOptionsPreview () {
  log('handleClickForOptionsPreview')

  resize()
}

export function handleClickForPrintConfigurationToUnity () {
  log('handleClickForPrintConfigurationToUnity')

  socket.emit('printImage')
}

export function handleClickForPrintConfiguration () {
  log('handleClickForPrintConfiguration')

  global.print()
}

export function handleClickForPrintOptionsSummary () {
  log('handleClickForPrintOptionsSummary')

  global.print()
}

/**
 *  Options Preview -
 *      - available in `web`, etc
 *      - not available in `dealership`, etc
 */

export function handleClickForOptionsPreviewHide () {
  log('handleClickForOptionsPreviewHide')

  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  requestAnimationFrame(() => {
    document.body.classList.remove('options-preview')

    document.querySelectorAll('#renderapp .options-preview img')
      .forEach((img) => {
        img.className = 'hide'
      })

    document.querySelectorAll('#renderapp .summary-show')
      .forEach((element) => {
        element.addEventListener('click', handleClickForOptionsPreviewShow, { capture: true, bubbles: false, passive: true })
      })
  })
}

export function handleClickForOptionsPreviewShow () {
  log('handleClickForOptionsPreviewShow')

  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  requestAnimationFrame(() => {
    document.body.classList.add('options-preview')

    requestImageForDefaultCamera()

    document.querySelectorAll('#renderapp .summary-show')
      .forEach((element) => {
        element.removeEventListener('click', handleClickForOptionsPreviewShow, { capture: true, bubbles: false, passive: true })
      })
  })
}

/**
 *  Options Summary -
 *      - available in `web`, etc
 *      - available in `dealership`, etc
 */

export function handleClickForPrintOptionsSummaryHide () {
  log('handleClickForPrintOptionsSummaryHide')

  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  requestAnimationFrame(() => {
    const {
      classList
    } = document.body

    classList.remove('print-options-summary')
    classList.remove('options-preview')
    classList.add('print-configuration')
  })
}

export function handleClickForPrintOptionsSummaryShow () {
  log('handleClickForPrintOptionsSummaryShow')

  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  requestAnimationFrame(() => {
    const {
      classList
    } = document.body

    classList.remove('print-configuration')
    classList.remove('options-preview')
    classList.add('print-options-summary')
  })
}

/**
 *  Summary Change
 *      - available in `web`, etc
 *      - not available in `dealership`, etc
 */

export function handleClickForPrintSummaryChange () {
  log('handleClickForPrintSummaryChange')

  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  requestAnimationFrame(() => {
    const {
      classList
    } = document.body

    classList.toggle('print-configuration')
    classList.toggle('options-preview')
    classList.toggle('print-options-summary')
  })
}

/**
 *  Print Change For Page Mode
 *      - not available in `web`, etc
 *      - available in `dealership`, etc
 */

export function handleMediaPrintChangeForPageMode ({ matches = false }) {
  /**
   *  log('handleMediaPrintChangeForPageMode')
   */

  return (
    matches
      ? appendOptionsSummaryClassNameForPrint()
      : removeOptionsSummaryClassNameForPrint()
  )
}

/**
 *  Print Change
 *      - available in `web`, etc
 *      - available in `dealership`, etc
 */

export function handleMediaPrintChange ({ matches = false }) {
  /**
   *  log('handleMediaPrintChange')
   */

  return (
    matches
      ? appendImageForPrint()
      : removeImageForPrint()
  )
}

/**
 *  Match Media
 */

export function handleMatchMediaExtraSmallChange ({ matches = false }) {
  /**
   *  log('handleMatchMediaExtraSmallChange')
   */

  if (matches) serverRequest()
}

export function handleMatchMediaSmallChange ({ matches = false }) {
  /**
   *  log('handleMatchMediaSmallChange')
   */

  if (matches) serverRequest()
}

export function handleMatchMediaMediumChange ({ matches = false }) {
  /**
   *  log('handleMatchMediaMediumChange')
   */

  if (matches) serverRequest()
}

export function handleMatchMediaLargeChange ({ matches = false }) {
  /**
   *  log('handleMatchMediaLargeChange')
   */

  if (matches) serverRequest()
}

export function handleMatchMediaExtraLargeChange ({ matches = false }) {
  /**
   *  log('handleMatchMediaExtraLarge')
   */

  if (matches) serverRequest()
}

/**
 *  History
 */
export function handlePopStateChange () {
  /**
   *  log('handlePopStateChange')
   */

  getRenderDataFromURLSearchParams()

  resetAllButtonObjectsChanged()

  updateAllButtonObjectsSelected()

  updateAllButtonObjectsDisabled()

  setBackendConfiguration(getBackendConfigurationFromButtonObjects())

  updateAllButtonObjectsChanged()

  updateAllButtonObjectsPrices()

  renderAllButtonObjects()

  render()

  change()

  serverRequest()
}

/**
 *  Handle content loaded (before/after `Init` wwithout interfering with `Init`)
 */

export function handleContentLoaded () {
  log('handleContentLoaded')

  getRenderDataFromURLSearchParams()

  document.getElementById('renderapp')
    .addEventListener('change', setRenderDataToURLSearchParams, { capture: true, bubbles: false, passive: true })

  if (isPageModeWeb() || isPageModePreRender()) {
    queryMatchMediaExtraSmall()
      .addEventListener('change', handleMatchMediaExtraSmallChange)

    queryMatchMediaSmall()
      .addEventListener('change', handleMatchMediaSmallChange)

    queryMatchMediaMedium()
      .addEventListener('change', handleMatchMediaMediumChange)

    queryMatchMediaLarge()
      .addEventListener('change', handleMatchMediaLargeChange)

    queryMatchMediaExtraLarge()
      .addEventListener('change', handleMatchMediaExtraLargeChange)
  }

  global.addEventListener('popstate', handlePopStateChange, { capture: true, bubbles: false, passive: true })
}

/**
 *  Register event listeners
 */

export function registerEventListeners () {
  log('registerEventListeners')

  const buttonObjectsForGroupModels = getButtonObjectsForGroupModels()

  /**
   *  Attach before other change listeners (1)
   *
   *      - When an <input /> in the `models` group is changed, delete `Changed` boolean from
   *        every `buttonObject` in `ButtonObjects` for every group including `models`
   *      - Assign `Changed = true` to the `buttonObject` for this <input /> element
   */
  buttonObjectsForGroupModels
    .forEach((buttonObject) => {
      if (hasButtonObjectUIElement(buttonObject)) {
        const UIElement = getButtonObjectUIElement(buttonObject)

        UIElement.addEventListener('change', getHandleChangeForGroupModels(buttonObject), { capture: true, bubbles: false, passive: true })
      }
    })

  const buttonObjectsForGroupPack = getButtonObjectsForGroupPack()

  /**
   *  Attach before other change listeners (2)
   *
   *      - When an <input /> in the `pack` group is changed, delete `Changed` boolean from
   *        every `buttonObject` in `ButtonObjects` for every group excluding `models`
   *      - Assign `Changed = true` to the `buttonObject` for this <input /> element
   */
  buttonObjectsForGroupPack
    .forEach((buttonObject) => {
      if (hasButtonObjectUIElement(buttonObject)) {
        const UIElement = getButtonObjectUIElement(buttonObject)

        UIElement.addEventListener('change', getHandleChangeForGroupPack(buttonObject), { capture: true, bubbles: false, passive: true })
      }
    })

  const buttonObjects = (
    buttonObjectsForGroupModels
      .concat(buttonObjectsForGroupPack)
  )

  /**
   *  Attach before other change listeners (3)
   *
   *      - When an <input /> in the group is changed, delete `Changed` boolean from
   *        every `buttonObject` in that group
   *      - Assign `Changed = true` to the `buttonObject` for this <input /> element
   */
  getAllButtonObjects()
    .filter((buttonObject) => !buttonObjects.includes(buttonObject))
    .forEach((buttonObject) => {
      if (hasButtonObjectUIElement(buttonObject)) {
        const UIElement = getButtonObjectUIElement(buttonObject)

        UIElement.addEventListener('change', getHandleChangeForGroup(buttonObject), { capture: true, bubbles: false, passive: true })
      }
    })

  /**
   *  Click listener (1)
   *
   *      - Bound to any `a` under the class `send-to-a-retailer`
   */
  document.querySelectorAll('#renderapp:not(.dealership, .plus, .vr) button.retailer-send')
    .forEach((element) => {
      element.addEventListener('click', handleClickForSendToARetailer, { capture: true, bubbles: false, passive: false })
    })

  /**
   *  Click listener (2 - A)
   *
   *      - Bound to any `button` with the class `share`
   */
  document.querySelectorAll('#renderapp button.share')
    .forEach((element) => {
      element.addEventListener('click', handleClickForShare, { capture: true, bubbles: false, passive: true })
    })

  /**
   *  Click listener (2 - B)
   *
   *      - Bound to any `button` with the class `reset`
   */
  document.querySelectorAll('#renderapp button.reset')
    .forEach((element) => {
      element.addEventListener('click', handleClickForReset, { capture: true, bubbles: false, passive: true })
    })

  /**
   *  Click listener (3 - A) - Local print
   *
   *      - Bound to any `button` with the class `print-configuration`
   */
  document.querySelectorAll('#renderapp:not(.dealership, .plus, .vr) button.print-configuration')
    .forEach((element) => {
      element.addEventListener('click', handleClickForPrintConfiguration, { capture: true, bubbles: false, passive: true })
    })

  /**
   *  Click listener (3 - B) - Unity print
   *
   *      - Bound to any `button` with the class `print-configuration`
   */
  document.querySelectorAll('#renderapp.dealership button.print-configuration, #renderapp.plus button.print-configuration, #renderapp.vr button.print-configuration')
    .forEach((element) => {
      element.addEventListener('click', handleClickForPrintConfigurationToUnity, { capture: true, bubbles: false, passive: true })
    })

  /**
   *  Click listener (4)
   *
   *      - Bound to any `button` with the class `print-options-summary`
   */
  document.querySelectorAll('#renderapp button.print-options-summary')
    .forEach((element) => {
      element.addEventListener('click', handleClickForPrintOptionsSummary, { capture: true, bubbles: false, passive: true })
    })

  /**
   *  Click listener (5 - A)
   *
   *      - Bound to any element with the class `summary-hide`
   */
  document.querySelectorAll('#renderapp:not(.dealership, .plus, .vr) .summary-hide')
    .forEach((element) => {
      element.addEventListener('click', handleClickForOptionsPreviewHide, { capture: true, bubbles: false, passive: true })
    })

  /**
   *  Click listener (5 - B)
   *
   *      - Bound to any element with the class `summary-hide`
   */
  document.querySelectorAll('#renderapp.dealership .summary-hide, #renderapp.plus .summary-hide, #renderapp.vr .summary-hide')
    .forEach((element) => {
      element.addEventListener('click', handleClickForPrintOptionsSummaryHide, { capture: true, bubbles: false, passive: true })
    })

  /**
   *  Click listener (6 - A)
   *
   *      - Bound to any element beneath #renderapp with the class `summary-show`
   *      - Bound to any element beneath #options with the class `view-summary`
   */
  document.querySelectorAll('#renderapp:not(.dealership, .plus, .vr) .summary-show, #renderapp:not(.dealership, .plus, .vr) #options .view-summary')
    .forEach((element) => {
      element.addEventListener('click', handleClickForOptionsPreviewShow, { capture: true, bubbles: false, passive: true })
    })

  /**
   *  Click listener (6 - B)
   *
   *      - Bound to any element beneath #renderapp with the class `summary-show`
   *      - Bound to any element beneath #options with the class `view-summary`
   */
  document.querySelectorAll('#renderapp.dealership .summary-show, #renderapp.plus .summary-show, #renderapp.vr .summary-show, #renderapp.dealership #options .view-summary, #renderapp.plus #options .view-summary, #renderapp.vr #options .view-summary')
    .forEach((element) => {
      element.addEventListener('click', handleClickForPrintOptionsSummaryShow, { capture: true, bubbles: false, passive: true })
    })

  /**
   *  Click listener (7)
   *
   *      - Bound to any element with the class `summary-options-hide`
   */
  document.querySelectorAll('#renderapp:not(.dealership, .plus, .vr) .summary-options-hide')
    .forEach((element) => {
      element.addEventListener('click', handleClickForPrintOptionsSummaryHide, { capture: true, bubbles: false, passive: true })
    })

  /**
   *  Click listener (8)
   *
   *      - Bound to any element with the class `summary-options-show`
   */
  document.querySelectorAll('#renderapp:not(.dealership, .plus, .vr) .summary-options-show')
    .forEach((element) => {
      element.addEventListener('click', handleClickForPrintOptionsSummaryShow, { capture: true, bubbles: false, passive: true })
    })

  /**
   *  Click listener (9)
   *
   *      - Bound to any element with the class `summary-change`
   */
  document.querySelectorAll('#renderapp:not(.dealership, .plus, .vr) .summary-change')
    .forEach((element) => {
      element.addEventListener('click', handleClickForPrintSummaryChange, { capture: true, bubbles: false, passive: true })
    })

  /**
   *  Click listener (10)
   *
   *      - Bound to any element beneath #renderapp with the class `summary-show`
   *      - Bound to any element beneath #renderapp with the class `summary-hide`
   *      - Bound to any element beneath #options with the class `view-summary`
   */
  document.querySelectorAll('#renderapp .summary-show, #renderapp .summary-hide, #renderapp #options .view-summary')
    .forEach((element) => {
      element.addEventListener('click', handleClickForOptionsPreview, { capture: true, bubbles: false, passive: true })
    })

  /**
   *  Match Media Listeners
   */
  const matchMediaQuery = global.matchMedia('print')
  /**
   *
   */
  matchMediaQuery
    .addListener(handleMediaPrintChange)
  /**
   *
   */
  if (isPageModeDealership() || isPageModePlus() || isPageModeVR()) {
    /**
     *
     */
    matchMediaQuery
      .addListener(handleMediaPrintChangeForPageMode)
  }

  getAllButtonObjects()
    .filter(({ Type }) => Type === 'dropdown')
    .reduce((accumulator, buttonObject) => {
      if (hasButtonObjectUIElementContainer(buttonObject)) {
        const UIElementContainer = getButtonObjectUIElementContainer(buttonObject)

        if (accumulator.some((buttonObject) => UIElementContainer === getButtonObjectUIElementContainer(buttonObject))) {
          const buttonObjects = accumulator.find((buttonObject) => UIElementContainer === getButtonObjectUIElementContainer(buttonObject))

          const {
            ButtonObjects = []
          } = buttonObjects

          Object.assign(buttonObjects, { ButtonObjects: ButtonObjects.concat(buttonObject) })

          return (
            accumulator
          )
        }

        return (
          accumulator.concat({ UIElementContainer, ButtonObjects: [buttonObject] })
        )
      }

      return accumulator
    }, [])
    .forEach((buttonObjects) => {
      if (hasButtonObjectUIElementContainer(buttonObjects)) {
        const UIElementContainer = getButtonObjectUIElementContainer(buttonObjects)

        UIElementContainer.addEventListener('change', getHandleChangeButtonObjectForTypeDropdown(buttonObjects), { capture: true, bubbles: true, passive: false })
      }
    })

  getAllButtonObjects()
    .filter(({ Type }) => Type === 'radio' || Type === 'checkbox')
    .forEach((buttonObject) => {
      if (hasButtonObjectUIElement(buttonObject)) {
        const UIElement = getButtonObjectUIElement(buttonObject)

        UIElement.addEventListener('change', getHandleChangeButtonObject(buttonObject), { capture: true, bubbles: true, passive: false })
      }
    })

  getAllButtonObjects()
    .filter(hasButtonObjectPairWithRule)
    .forEach((buttonObject) => {
      if (hasButtonObjectUIElement(buttonObject)) {
        const UIElement = getButtonObjectUIElement(buttonObject)

        UIElement.addEventListener('change', getHandleChangeForPairWith(buttonObject), { capture: true, bubbles: false, passive: true })
      }
    })

  DEFAULT_CAMERA_IMAGE_CACHE
    .addEventListener('load', handleDefaultCameraImageCacheLoad)

  CURRENT_CAMERA_IMAGE_CACHE
    .addEventListener('load', handleCurrentCameraImageCacheLoad)

  if (isPageModeWeb() || isPageModePreRender()) { // Web or Pre-Render
    registerEventListenersForPageModeWeb()
  } else if (isPageModeDealership()) { // Dealership
    registerEventListenersForPageModeDealership()
  } else if (isPageModeWebGL()) { // WebGL
    registerEventListenersForPageModeWebGL()
  }
}

export function registerEventListenersForPageModeWeb () {
  log('registerEventListenersForPageModeWeb')

  const trackpad = document.getElementById('trackpad')

  let isTouchMove = false

  let fx1
  let fx2

  function handleNextCamera ({ clientX }) {
    fx1 = clientX

    nextCamera()
  }

  function handlePrevCamera ({ clientX }) {
    fx1 = clientX

    prevCamera()
  }

  // Mouse support
  document.querySelectorAll('#renderapp .controls button.next')
    .forEach((element) => {
      element.addEventListener('click', handleNextCamera, { capture: true, bubbles: false, passive: true })
    })

  document.querySelectorAll('#renderapp .controls button.prev')
    .forEach((element) => {
      element.addEventListener('click', handlePrevCamera, { capture: true, bubbles: false, passive: true })
    })

  // Touch support
  trackpad.addEventListener('touchstart', function (e) {
    if (e.touches.length === 1) {
      const [
        touch
      ] = e.touches
      fx1 = touch.clientX
    }
  }, { capture: true, bubbles: false, passive: true })

  trackpad.addEventListener('touchmove', function (e) {
    e.preventDefault()

    if (e.touches.length === 1) {
      isTouchMove = true

      const [
        touch
      ] = e.touches

      fx2 = touch.clientX
    }
  }, { capture: true, bubbles: false, passive: false })

  trackpad.addEventListener('touchend', function (e) {
    if (isTouchMove) {
      isTouchMove = false

      const offset = fx2 - fx1
      if (offset < 0) {
        prevCamera()
      } else if (offset > 0) {
        nextCamera()
      }
    }
  }, { capture: true, bubbles: false, passive: true })
}

export function registerEventListenersForPageModeDealership () {
  log('registerEventListenersForPageModeDealership')

  const trackpad = document.getElementById('trackpad')

  let isMouseDown = false

  let fx1
  let fy1

  let touchDistance = 0

  // Mouse support
  trackpad.addEventListener('mousedown', function (e) {
    isMouseDown = true

    fx1 = e.offsetX
    fy1 = e.offsetY
  }, { capture: true, bubbles: false, passive: true })

  trackpad.addEventListener('mousemove', function (e) {
    if (isMouseDown) {
      orbitSpeed(e.offsetX - fx1, e.offsetY - fy1)
      fx1 = e.offsetX
      fy1 = e.offsetY
    }
  }, { capture: true, bubbles: false, passive: true })

  trackpad.addEventListener('mouseup', function (e) {
    fx1 = e.offsetX
    fy1 = e.offsetY

    isMouseDown = false
  }, { capture: true, bubbles: false, passive: true })

  trackpad.addEventListener('wheel', function (e) {
    e.preventDefault()

    let normalized = 0

    if (!e.wheelDelta) { normalized = -e.deltaY } else { normalized = e.wheelDelta }

    // Get direction
    if (normalized > 0) { normalized = 1 } else if (normalized < 0) { normalized = -1 }

    // Send command to server
    zoomSpeed(-normalized * scrollMultiplier * -0.01)
  }, { capture: true, bubbles: false, passive: false })

  // Touch support
  trackpad.addEventListener('touchstart', function (e) {
    if (e.touches.length === 1) {
      const [
        touch
      ] = e.touches

      fx1 = touch.clientX
      fy1 = touch.offsetY
    } else if (e.touches.length === 2) {
      const [
        one,
        two
      ] = e.touches

      touchDistance = distance(one.clientX, one.clientY, two.clientX, two.clientY)
    }
  }, { capture: true, bubbles: false, passive: true })

  trackpad.addEventListener('touchmove', function (e) {
    e.preventDefault()

    if (e.touches.length === 1) {
      const [
        touch
      ] = e.touches

      orbitSpeed(touch.clientX - fx1, touch.clientY - fy1)

      fx1 = touch.clientX
      fy1 = touch.clientY
    } else if (e.touches.length === 2) {
      const [
        one,
        two
      ] = e.touches

      const was = touchDistance
      const now = distance(one.clientX, one.clientY, two.clientX, two.clientY)

      zoomSpeed((now - was) * scrollMultiplier * 0.001)

      touchDistance = now
    }
  }, { capture: true, bubbles: false, passive: false })

  trackpad.addEventListener('touchend', function (e) {
    if (e.touches.length > 0) {
      const [
        touch
      ] = e.touches
      fx1 = touch.clientX
      fy1 = touch.offsetY
    }
  }, { capture: true, bubbles: false, passive: true })
}

export function registerEventListenersForPageModeWebGL () {
  log('registerEventListenersForPageModeWebGL')

  const viewport = document.getElementById('viewport')

  let isMouseDown = false

  // WebGL Params
  let eventX
  let eventY

  let touchDistance = 0

  let isImageRequested = false

  // Mouse support
  viewport.addEventListener('mousedown', function (e) {
    if (e.target === gl.canvas) {
      showCanvas() // gl.showCanvas();
      eventX = e.clientX
      eventY = e.clientY
      isImageRequested = true
      isMouseDown = true
    }
  }, { capture: true, bubbles: false, passive: true })

  document.body.addEventListener('mousemove', function (e) {
    if (isMouseDown) {
      e.preventDefault()

      const distX = e.clientX - eventX
      const distY = e.clientY - eventY

      glOrbit(distX, distY)

      eventX = e.clientX
      eventY = e.clientY
    }
  }, { capture: true, bubbles: false, passive: false })

  document.body.addEventListener('mouseup', function (e) {
    isMouseDown = false

    if (isImageRequested) {
      requestImage()
      isImageRequested = false
    }
  }, { capture: true, bubbles: false, passive: true })

  viewport.addEventListener('wheel', function (e) {
    if (e.target === gl.canvas) {
      e.preventDefault()

      let normalized = 0

      if (!e.wheelDelta) {
        normalized = -e.deltaY
      } else {
        normalized = e.wheelDelta
      }

      if (normalized > 0) { normalized = 1 } if (normalized < 0) { normalized = -1 }

      glZoom(-normalized * scrollMultiplier)

      if (timeout) {
        showCanvas()
        clearTimeout(timeout)
      }

      timeout = setTimeout(function () {
        requestImage()
      }, 300)
    }
  }, { capture: true, bubbles: false, passive: false })

  // Touch support
  viewport.addEventListener('touchstart', function (e) {
    if (e.target === gl.canvas) {
      if (e.touches.length === 1) {
        const [
          touch
        ] = e.touches
        eventX = touch.clientX
        eventY = touch.clientY
      } else if (e.touches.length === 2) {
        const [
          one,
          two
        ] = e.touches

        eventX = one.clientX
        eventY = one.clientY

        touchDistance = glDistance(one, two)
      }

      showCanvas()
    }
  }, { capture: true, bubbles: false, passive: true })

  viewport.addEventListener('touchmove', function (e) {
    if (e.target === gl.canvas) {
      e.preventDefault()

      if (e.touches.length === 1) {
        const [
          touch
        ] = e.touches
        const deltaX = touch.clientX - eventX
        const deltaY = touch.clientY - eventY

        glOrbit(deltaX, deltaY)

        eventX = touch.clientX
        eventY = touch.clientY
      } else if (e.touches.length === 2) {
        const [
          one,
          two
        ] = e.touches

        glZoom(touchDistance - glDistance(one, two))

        touchDistance = glDistance(one, two)
      }
    }
  }, { capture: true, bubbles: false, passive: false })

  viewport.addEventListener('touchend', function (e) {
    if (e.target === gl.canvas) {
      if (e.touches.length === 0) {
        requestImage()
      } else {
        const [
          touch
        ] = e.touches
        eventX = touch.clientX
        eventY = touch.clientY
      }
    }
  }, { capture: true, bubbles: false, passive: true })
}

/**
 *  Configure viewport
 */

export function configureViewport () {
  log('configureViewport')

  if (hasPageMode()) {
    /**
     *  Gate the `PageMode` configuration value
     */
    const pageMode = getPageMode().toLocaleLowerCase()

    const {
      requestAnimationFrame = function requestAnimationFrame () {
        log('`requestAnimationFrame` is not available')
      }
    } = global

    requestAnimationFrame(() => {
      /**
       *  Assign it as a classname to the `renderapp` element
       */
      document.getElementById('renderapp')
        .classList.add(pageMode)
    })
  }

  if (isPageModeWeb() || isPageModePreRender()) {
    configureViewportForPageModeWeb()
  } else if (isPageModeDealership() || isPageModePlus() || isPageModeVR()) {
    configureViewportForPageModeDealership()
  } else if (isPageModeWebGL()) {
    configureViewportForPageModeWebGL()
  } else if (isPageModeReview()) {
    configureViewportForPageModeReview()
  }
}

export function configureViewportForPageModeWeb () {
  log('configureViewportForPageModeWeb')

  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  requestAnimationFrame(() => {
    const viewport = document.getElementById('viewport')
    const gameContainer = document.getElementById('gameContainer')

    viewport.removeChild(gameContainer)

    const webGLCameras = document.getElementById('webglcameras')
    if (webGLCameras) webGLCameras.parentElement.removeChild(webGLCameras)
  })
}

export function configureViewportForPageModeDealership () {
  log('configureViewportForPageModeDealership')

  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  requestAnimationFrame(() => {
    const viewport = document.getElementById('viewport')
    const img1 = document.getElementById('img1')
    const img2 = document.getElementById('img2')
    const gameContainer = document.getElementById('gameContainer')
    const controls = document.getElementsByClassName('controls')

    viewport.removeChild(img1)
    viewport.removeChild(img2)
    viewport.removeChild(gameContainer)

    Array.from(controls)
      .forEach((element) => {
        element.parentElement.removeChild(element)
      })

    if (isPageModePlus() || isPageModeVR()) {
      const webGLCameras = document.getElementById('webglcameras')
      if (webGLCameras) webGLCameras.parentElement.removeChild(webGLCameras)
    }
  })
}

export function configureViewportForPageModeWebGL () {
  log('configureViewportForPageModeWebGL')

  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  requestAnimationFrame(() => {
    const viewport = document.getElementById('viewport')
    const controls = document.getElementsByClassName('controls')
    const trackpad = document.getElementById('trackpad')

    Array.from(controls)
      .forEach((element) => {
        element.parentElement.removeChild(element)
      })

    viewport.removeChild(trackpad)
  })
}

export function configureViewportForPageModeReview () {
  log('configureViewportForPageModeReview')

  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  requestAnimationFrame(() => {
    const viewport = document.getElementById('viewport')
    const img1 = document.getElementById('img1')
    const img2 = document.getElementById('img2')
    const gameContainer = document.getElementById('gameContainer')
    const controls = document.getElementsByClassName('controls')
    const trackpad = document.getElementById('trackpad')

    viewport.removeChild(img1)
    viewport.removeChild(img2)
    viewport.removeChild(gameContainer)

    Array.from(controls)
      .forEach((element) => {
        element.parentElement.removeChild(element)
      })

    viewport.removeChild(trackpad)

    const webGLCameras = document.getElementById('webglcameras')
    if (webGLCameras) webGLCameras.parentElement.removeChild(webGLCameras)

    if (hasReviewCameras()) {
      const reviewCameras = getReviewCameras()

      // Add images
      for (const reviewCamera of reviewCameras) {
        // Hyper-link
        const anchor = document.createElement('a')
        anchor.href = '#'
        anchor.target = '_blank'

        // Image
        const img = document.createElement('img')
        img.id = reviewCamera.Id
        img.width = reviewCamera.Width
        img.height = reviewCamera.Height
        img.addEventListener('load', function () {
          imagesToLoad -= 1

          if (imagesToLoad <= 0) {
            /**
             *  Always reset the timeout if there is one
             */
            resetLoadingTimeout()

            /**
             *  Hide the loader!
             */
            createHideLoadingTimeout()
          }
        })

        anchor.appendChild(img)

        // Finally
        viewport.appendChild(anchor)
      }
    }
  })
}

// Public
export function Init (settings = {}) {
  log('Init')

  // Set debug mode
  if (settings.Debug) debug.enable('renderapp')

  // PageMode
  if (hasPageModeIn(settings)) {
    const pageMode = getPageModeFrom(settings).toLocaleLowerCase()

    switch (pageMode) {
      case 'live':
        setPageMode(WEB)
        break

      case 'liveplus':
        setPageMode(WEBGL)
        break

      default:
        setPageMode(pageMode)
        break
    }
  }

  // Configuration
  if (hasConfigurationIn(settings)) setConfiguration(normalise(getConfigurationFrom(settings)))

  // Backend Configuration
  if (hasBackendConfigurationIn(settings)) setBackendConfiguration(normalise(getBackendConfigurationFrom(settings)))

  // Language
  if (hasLanguageIn(settings)) setLanguage(getLanguageFrom(settings))

  // Currency
  if (hasCurrencyIn(settings)) setCurrency(getCurrencyFrom(settings))

  // Currency Digits
  if (hasCurrencyDigitsIn(settings)) setCurrencyDigits(getCurrencyDigitsFrom(settings))

  // Region Code
  if (hasRegionCodeIn(settings)) setRegionCode(getRegionCodeFrom(settings))

  // Region Configuration
  if (hasRegionConfigurationIn(settings)) setRegionConfiguration(getRegionConfigurationFrom(settings))

  // Socket
  if (hasSocketIn(settings)) setSocket(getSocketFrom(settings))

  // Request the server settings
  return (
    requestSettings()
  )
}

function InitLogin (id) {
  document.getElementById(id).value = (new URL(getWindowLocationHref())).search
}

function updateConfiguration (configuration = '') {
  log('updateConfiguration')

  const CONFIGURATION = normalise(configuration)

  const hasChanged = (
    getConfigurationFromButtonObjects() !== CONFIGURATION)

  if (hasChanged) {
    setConfiguration(CONFIGURATION)

    resetAllButtonObjectsChanged()

    updateAllButtonObjectsSelected()

    updateAllButtonObjectsDisabled()

    setBackendConfiguration(getBackendConfigurationFromButtonObjects())

    updateAllButtonObjectsChanged()

    updateAllButtonObjectsPrices()

    renderAllButtonObjects()

    render()

    change()

    serverRequest()
  }
}

{
  const {
    document: {
      readyState
    } = {}
  } = global

  try {
    info(`Initialising at "${readyState}" ...`)

    if (readyState === 'loading') {
      global.addEventListener('DOMContentLoaded', handleContentLoaded)

      info('Deferring initialisation until "complete"')
    } else {
      handleContentLoaded()

      info(`Initialisation at "${readyState}" succeeded`)
    }
  } catch {
    info(`Failed initialisation at "${readyState}"`)
  }
}

// Public API
export default {
  Init,
  InitLogin,
  UpdateConfiguration: updateConfiguration,
  GetPriceColumn: getPriceColumn
}
