import debug from 'debug'

const log = debug('renderapp:render-data')

log('`renderapp` is awake')

/**
 *  Transform
 */

export default function transformToButtonObject (value) {
  /**
   *  log('transformToButtonObject')
   */

  const [
    Visibility,
    AutoGenerate,
    VisibilityRule,
    FrontEndRule,
    BackEndRules,
    PairWithRule,
    Type,
    Group,
    Bloblet,
    Range,
    DefaultRule,
    ParentElement,
    CameraIndices,
    WebGLCamIDs,
    EmailState,
    Name,
    Description,
    Thumbnail,
    Logo,
    BasePrice,
    /**
     *  Get `Prices` with rest from position 20 (to n)
     */
    ...Prices
  ] = value

  return {
    Visibility,
    AutoGenerate,
    VisibilityRule,
    FrontEndRule,
    BackEndRules,
    PairWithRule,
    Type,
    Group,
    Bloblet,
    Range,
    DefaultRule,
    ParentElement,
    /**
     *  Map `CameraIndices` with `parseInt`
     */
    CameraIndices: (CameraIndices) ? CameraIndices.split(',').map((cameraIndex) => parseInt(cameraIndex, 10)) : null,
    WebGLCamIDs: (WebGLCamIDs) ? WebGLCamIDs.split(',') : null,
    EmailState,
    Name,
    Description,
    Thumbnail,
    Logo,
    /**
     *  Get `BasePrice` with `parseFloat`
     */
    BasePrice: parseFloat(BasePrice, 10),
    /**
     *  Map `Prices` with `parseFloat`
     */
    Prices: Prices.map((price) => parseFloat(price, 10))
  }
}
