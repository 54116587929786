import debug from 'debug'

import {
  hasConfiguration,
  getConfiguration,
  setConfiguration,

  getRegionConfiguration,

  setBasePrice,
  setOptionsPrice,

  truthy,
  dedupe
} from '~/client/assets/js/common/render-data'

import getPriceColumn from '~/client/assets/js/renderapp/render-data/price-column'

import {
  isSelected,
  isEnabled,
  isChanged,
  isDisabled,

  isDisabledForConfiguration,

  isGroupEnvironments,
  isGroupCameras,

  getButtonObjectPrice,

  hasButtonObjectBasePrice,
  getButtonObjectBasePrice,

  hasButtonObjectFrontEndRule,
  getButtonObjectFrontEndRule,

  getIsConfigured
} from '~/client/assets/js/renderapp/render-data/button-object'

import {
  getAllButtonObjects,

  getConfigurationFromButtonObjects,

  getGroupsFromButtonObjects,
  getGroupsFrom,

  hasAnyButtonObjectsEnabledForGroup,

  getButtonObjectsChangedForGroup,
  getButtonObjectsEnabledForGroup
} from '~/client/assets/js/renderapp/render-data/button-objects'

import {
  updateUIElementSelected,
  updateUIElementDisabled
} from '~/client/assets/js/renderapp/render-data/ui-element/change'

import {
  hasDefaultConfigurationFor,
  getDefaultConfigurationFor
} from '~/client/assets/js/renderapp/default-configuration'

const log = debug('renderapp:render-data/button-objects/update')

log('`renderapp` is awake')

export function getToButtonObject (buttonObjects = getAllButtonObjects()) {
  /**
   *  log('getToButtonObject')
   */

  return function toButtonObject (rule) {
    /**
     *  log('toButtonObject')
     */

    const frontEndRule = '+'.concat(rule)

    return (
      buttonObjects
        .filter(hasButtonObjectFrontEndRule)
        .find((buttonObject) => (
          frontEndRule === getButtonObjectFrontEndRule(buttonObject)
        ))
    )
  }
}

export function resetAllButtonObjectsChanged () {
  /**
   *  log('resetAllButtonObjectsChanged')
   */

  getAllButtonObjects()
    .forEach((buttonObject) => {
      delete buttonObject.Changed
    })
}

export function resetButtonObjectsChangedForGroup (group) {
  /**
   *  log('resetButtonObjectsChangedForGroup')
   */

  getButtonObjectsChangedForGroup(group)
    .forEach((buttonObject) => {
      delete buttonObject.Changed
    })
}

export function updateAllButtonObjectsChanged () {
  /**
   *  log('updateAllButtonObjectsChanged')
   */

  if (hasConfiguration()) {
    const currentConfiguration = getConfiguration()

    if (hasDefaultConfigurationFor(currentConfiguration)) {
      const defaultConfiguration = getDefaultConfigurationFor(currentConfiguration)

      const CURRENT_CONFIGURATION = currentConfiguration.split('+').filter(truthy).reduce(dedupe, [])
      const DEFAULT_CONFIGURATION = defaultConfiguration.split('+').filter(truthy).reduce(dedupe, [])

      const toButtonObject = getToButtonObject()

      const CURRENT = CURRENT_CONFIGURATION
        .map(toButtonObject)

      const DEFAULT = DEFAULT_CONFIGURATION
        .map(toButtonObject)

      const CHANGED = CURRENT
        .filter((buttonObject) => !DEFAULT.includes(buttonObject))
        .filter((buttonObject) => !isGroupEnvironments(buttonObject))
        .filter((buttonObject) => !isGroupCameras(buttonObject))

      CHANGED
        .forEach((buttonObject) => {
          buttonObject.Changed = true
        })
    }
  }
}

export function updateAllButtonObjectsSelected () {
  /**
   *  log('updateAllButtonObjectsSelected')
   */

  getGroupsFromButtonObjects()
    .forEach(updateButtonObjectsSelectedForGroup)
}

export function updateButtonObjectsSelectedForGroup (group) {
  /**
   *  log('updateButtonObjectsSelectedForGroup')
   */

  if (hasAnyButtonObjectsEnabledForGroup(group)) {
    /**
     *  Get only enabled for this group!
     */
    const buttonObjects = getButtonObjectsEnabledForGroup(group)

    /**
     *  Is at least one <input /> selected?
     */
    if (buttonObjects.some(isSelected)) {
      /**
       *  Yes
       */
      const buttonObject = buttonObjects.find(isSelected)

      /**
       *  Was this a user choice?
       */
      if (isChanged(buttonObject)) return /* Yes. Exit */
    }

    let buttonObject

    const isConfigured = getIsConfigured(getConfiguration())

    /**
     *  Is at least one `buttonObject` in this group a match by `FrontEndRule` in `Configuration`?
     */
    if (buttonObjects.some(isConfigured)) {
      /**
       *  Yes
       */
      buttonObject = buttonObjects.find(isConfigured)
    } else {
      /**
       *  ... Or use the first `buttonObject` in this collection (since everything in this collection is enabled, the first will do)
       */
      ([
        buttonObject
      ] = buttonObjects)
    }

    if (buttonObject) updateUIElementSelected(buttonObject)
  }
}

export function updateAllButtonObjectsDisabled () {
  /**
   *  log('updateAllButtonObjectsDisabled')
   */

  /**
   *  Compute `buttonObject` visibility (and apply it) then return an array of `ButtonObjects` whose visibility has changed
   */
  const buttonObjects = getAllButtonObjects()
    /**
     *  Update `disabled` state according to visibility rules
     */
    .reduce(updateButtonObjectsDisabled, [])

  getGroupsFrom(buttonObjects)
    /**
     *  Update `selected` state for changed groups
     */
    .forEach(updateButtonObjectsSelectedForGroup)

  /**
   *  Get the current `Configuration` from Render Data
   */
  const configuration = getConfiguration()

  /**
   *  Get the current `Configuration` according to computed `buttonObject` visibility
   */
  const CONFIGURATION = getConfigurationFromButtonObjects()

  /**
   *  Has the `Configuration` changed?
   */
  if (configuration !== CONFIGURATION) {
    /**
     *  Yes, it has
     *
     *  Set the changed `Configuration` to Render Data
     */
    setConfiguration(CONFIGURATION)

    /**
     *  Execute `updateAllButtonObjectsDisabled()` again
     */
    updateAllButtonObjectsDisabled()
  }
}

/**
 *  A reducer
 */
export function updateButtonObjectsDisabled (accumulator, buttonObject) {
  /**
   *  log('updateButtonObjectsDisabled')
   */

  /**
   *  Go to the DOM
   */
  const was = isDisabled(buttonObject)

  /**
   *  Get the `RegionConfiguration` and `Configuration`
   */
  const regionConfiguration = getRegionConfiguration()
  const configuration = getConfiguration()

  /**
   *  Use the `RegionConfiguration` and `Configuration`
   */
  const now = isDisabledForConfiguration(buttonObject, regionConfiguration + configuration)

  if (was !== now) { // `disabled` has changed
    /**
     *  Change the `UIElement` property because other computation depends on it
     */
    updateUIElementDisabled(buttonObject, now)

    return (
      accumulator.concat(buttonObject)
    )
  }

  return accumulator
}

export function updateAllButtonObjectsPrices () {
  /**
   *  log('updateAllButtonObjectsPrices')
   */

  const {
    BasePrice: basePrice,
    OptionsPrice: optionsPrice
  } = renderAllButtonObjectsPrices()

  setBasePrice(basePrice)
  setOptionsPrice(optionsPrice)
}

export function updateButtonObjectsPrices (buttonObjects = getAllButtonObjects()) {
  /**
   *  log('updateButtonObjectsPrices')
   */

  const {
    BasePrice: basePrice,
    OptionsPrice: optionsPrice
  } = renderButtonObjectsPrices(buttonObjects)

  setBasePrice(basePrice)
  setOptionsPrice(optionsPrice)
}

export function renderAllButtonObjectsPrices () {
  /**
   *  log('renderAllButtonObjectsPrices')
   */

  return (
    getAllButtonObjects()
      .filter(isEnabled)
      .filter(isSelected)
      .reduce(reduceButtonObjectsPrices, {})
  )
}

export function renderButtonObjectsPrices (buttonObjects = getAllButtonObjects()) {
  /**
   *  log('renderButtonObjectsPrices')
   */

  return (
    buttonObjects
      .filter(isEnabled)
      .filter(isSelected)
      .reduce(reduceButtonObjectsPrices, {})
  )
}

export function reduceButtonObjectsPrices ({ BasePrice = 0, OptionsPrice = 0 }, buttonObject) {
  /**
   *  log('renderButtonObjectsPrices')
   */

  if (hasButtonObjectBasePrice(buttonObject)) {
    /**
     *  During initialisation all `BasePrice` values are transformed to a number or to NaN
     */
    const basePrice = getButtonObjectBasePrice(buttonObject)

    /**
     *  During initialisation all `Prices` values are transformed to a number or to NaN
     */
    const price = getButtonObjectPrice(buttonObject, getPriceColumn())

    if (!isNaN(basePrice)) {
      BasePrice = isNaN(price) ? 0 : price
    } else {
      OptionsPrice += isNaN(price) ? 0 : price
    }
  }

  return {
    BasePrice,
    OptionsPrice
  }
}
