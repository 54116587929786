import debug from 'debug'

import {
  MODELS,
  PACK,
  PAINTCOLOURS,
  SIZE,

  getRegionConfiguration,
  getConfiguration
} from '~/client/assets/js/common/render-data'

import getPriceColumn from '~/client/assets/js/renderapp/render-data/price-column'

import getPriceFormat from '~/client/assets/js/renderapp/render-data/price-format'

import {
  isSelected,
  isEnabled,
  isChanged,

  getButtonObjectName,
  getButtonObjectGroup,
  getButtonObjectPrice,

  isDisabledForConfiguration
} from '~/client/assets/js/renderapp/render-data/button-object'

import {
  getGroupsFrom,

  getAllButtonObjectsHasGroup,

  getButtonObjectsForGroupModels,
  getButtonObjectsForGroupPaintColours,
  getButtonObjectsForGroupChassisSize,
  getButtonObjectsForGroupPack,
  getButtonObjectsForGroup
} from '~/client/assets/js/renderapp/render-data/button-objects'

/**
 *  Options Summary
 **/

const log = debug('renderapp:render-data/ui/options-summary')

log('`renderapp` is awake')

/**
 *  `ButtonObject`
 */

export function createButtonObjectDisabled () {
  /**
   *  log('createButtonObjectDisabled')
   */

  const element = document.createElement('div')
  element.className = 'disabled'
  element.innerText = 'N/A'

  return element
}

export function createButtonObjectSelected (buttonObject, priceFormat = getPriceFormat(), priceColumn = getPriceColumn()) {
  /**
   *  log('createButtonObjectSelected')
   */

  const price = document.createElement('div')
  price.className = 'price'
  price.innerText = (
    priceFormat
      .format(getButtonObjectPrice(buttonObject, priceColumn))
  )

  const name = document.createElement('div')
  name.className = 'name'
  name.innerText = getButtonObjectName(buttonObject)

  const element = document.createElement('div')
  element.className = 'selected'

  element.appendChild(price)
  element.appendChild(name)

  return element
}

export function renderButtonObjectSelected (element, buttonObject, priceFormat = getPriceFormat(), priceColumn = getPriceColumn()) {
  /**
   *  log('renderButtonObjectSelected')
   */

  element.classList.remove('disabled')

  element.appendChild(createButtonObjectSelected(buttonObject, priceFormat, priceColumn))
}

export function renderButtonObjectDisabled (element) {
  /**
   *  log('renderButtonObjectDisabled')
   */

  element.classList.add('disabled')

  element.appendChild(createButtonObjectDisabled())
}

export function renderButtonObjectDefault (element) {
  /**
   *  log('renderButtonObjectDefault')
   */

  if (element.classList.contains('disabled')) return

  renderButtonObjectDisabled(element)
}

export function renderButtonObject (element, buttonObject, priceFormat = getPriceFormat(), priceColumn = getPriceColumn()) {
  /**
   *  log('renderButtonObject')
   */

  const regionConfiguration = getRegionConfiguration()
  const configuration = getConfiguration()

  if (isDisabledForConfiguration(buttonObject, regionConfiguration + configuration)) {
    renderButtonObjectDisabled(element)
  } else {
    renderButtonObjectSelected(element, buttonObject, priceFormat, priceColumn)
  }
}

/**
 *  `ButtonObjects`
 */

export function renderButtonObjectsForGroupModels (optionsSummary, buttonObjects = getButtonObjectsForGroupModels(), priceFormat = getPriceFormat(), priceColumn = getPriceColumn()) {
  /**
   *  log('renderButtonObjectsForGroupModels')
   */

  const element = optionsSummary.querySelector('[data-group=\'models\']')

  if (element) {
    element.querySelectorAll('.disabled, .selected')
      .forEach((child) => element.removeChild(child))

    const [
      buttonObject
    ] = buttonObjects
      .filter(isSelected)

    if (buttonObject) renderButtonObject(element, buttonObject, priceFormat, priceColumn)
  }
}

export function renderButtonObjectsForGroupPaintColours (optionsSummary, buttonObjects = getButtonObjectsForGroupPaintColours(), priceFormat = getPriceFormat(), priceColumn = getPriceColumn()) {
  /**
   *  log('renderButtonObjectsForGroupPaintColours')
   */

  const element = optionsSummary.querySelector('[data-group=\'paintcolours\']')

  if (element) {
    element.querySelectorAll('.disabled, .selected')
      .forEach((child) => element.removeChild(child))

    const [
      buttonObject
    ] = buttonObjects
      .filter(isSelected)

    if (buttonObject) renderButtonObject(element, buttonObject, priceFormat, priceColumn)
  }
}

export function renderButtonObjectsForGroupChassisSize (optionsSummary, buttonObjects = getButtonObjectsForGroupChassisSize(), priceFormat = getPriceFormat(), priceColumn = getPriceColumn()) {
  /**
   *  log('renderButtonObjectsForGroupChassisSize')
   */

  const element = optionsSummary.querySelector('[data-group=\'size\']')

  if (element) {
    element.querySelectorAll('.disabled, .selected')
      .forEach((child) => element.removeChild(child))

    const [
      buttonObject
    ] = buttonObjects
      .filter(isSelected)

    if (buttonObject) renderButtonObject(element, buttonObject, priceFormat, priceColumn)
  }
}

export function renderButtonObjectsForGroupPack (optionsSummary, buttonObjects = getButtonObjectsForGroupPack(), priceFormat = getPriceFormat(), priceColumn = getPriceColumn()) {
  /**
   *  log('renderButtonObjectsForGroupPack')
   */

  const element = optionsSummary.querySelector('[data-group=\'pack\']')

  if (element) {
    element.querySelectorAll('.disabled, .selected')
      .forEach((child) => element.removeChild(child))

    const [
      buttonObject
    ] = buttonObjects
      .filter(isSelected)

    if (buttonObject) renderButtonObject(element, buttonObject, priceFormat, priceColumn)
  }
}

export function getRenderButtonObjectsForGroup (optionsSummary, buttonObjects = getAllButtonObjectsHasGroup(), priceFormat = getPriceFormat(), priceColumn = getPriceColumn()) {
  /**
   *  log('getRenderButtonObjectsForGroup')
   */

  return function renderButtonObjectsForGroup (group) {
    /**
     *  log('renderButtonObjectsForGroup')
     */

    const element = optionsSummary.querySelector(`[data-group='${group}']`)

    if (element) {
      element.querySelectorAll('.disabled, .selected')
        .forEach((child) => element.removeChild(child))

      const [
        buttonObject
      ] = getButtonObjectsForGroup(group, buttonObjects)
        .filter(isEnabled)
        .filter(isSelected)
        .filter(isChanged)

      if (buttonObject) {
        return (
          renderButtonObject(element, buttonObject, priceFormat, priceColumn)
        )
      }

      renderButtonObjectDefault(element)
    }
  }
}

export function filterGroups (group) {
  /**
   *  log('filterGroups')
   */

  return (
    group !== MODELS &&
    group !== PACK &&
    group !== PAINTCOLOURS &&
    group !== SIZE
  )
}

export function filterButtonObjectsForGroups (buttonObject) {
  /**
   *  log('filterButtonObjectsForGroups')
   */

  const group = getButtonObjectGroup(buttonObject)

  return (
    group !== MODELS &&
    group !== PACK &&
    group !== PAINTCOLOURS &&
    group !== SIZE
  )
}

export default function render () {
  log('render')

  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  requestAnimationFrame(() => {
    const buttonObjects = getAllButtonObjectsHasGroup()

    const buttonObjectsForGroupModels = getButtonObjectsForGroupModels(buttonObjects)
    const buttonObjectsForGroupPack = getButtonObjectsForGroupPack(buttonObjects)
    const buttonObjectsForGroupPaintColours = getButtonObjectsForGroupPaintColours(buttonObjects)
    const buttonObjectsForGroupChassisSize = getButtonObjectsForGroupChassisSize(buttonObjects)

    /**
     *  Get all the groups
     */
    const groups = getGroupsFrom(buttonObjects)
      /**
       *  excluding the `models`, `paintcolours`, `size`, and `pack` groups
       */
      .filter(filterGroups)

    /**
     *  Get all the `buttonObjects` for all the groups
     */
    const buttonObjectsForGroups = buttonObjects
      /**
       *  excluding the `models`, `paintcolours`, `size`, and `pack` groups
       */
      .filter(filterButtonObjectsForGroups)

    const priceFormat = getPriceFormat()
    const priceColumn = getPriceColumn()

    document.querySelectorAll('#renderapp .options-summary')
      /**
       *  There may be more than one parent "Options summary" in the document
       */
      .forEach((optionsSummary) => {
        /**
         *  Always render the `models` group
         */
        renderButtonObjectsForGroupModels(optionsSummary, buttonObjectsForGroupModels, priceFormat, priceColumn)
        /**
         *  Always render the `pack` group
         */
        renderButtonObjectsForGroupPack(optionsSummary, buttonObjectsForGroupPack, priceFormat, priceColumn)
        /**
         *  Always render the `paintcolours` group
         */
        renderButtonObjectsForGroupPaintColours(optionsSummary, buttonObjectsForGroupPaintColours, priceFormat, priceColumn)
        /**
         *  Always render the `size` group
         */
        renderButtonObjectsForGroupChassisSize(optionsSummary, buttonObjectsForGroupChassisSize, priceFormat, priceColumn)
        /**
         *  Always render the groups excluding the `models`, `paintcolours`, `size`, and `pack` groups
         */
        groups
          .forEach(getRenderButtonObjectsForGroup(optionsSummary, buttonObjectsForGroups, priceFormat, priceColumn))
      })
  })
}
