import debug from 'debug'

import {
  PACK
} from '~/client/assets/js/common/render-data'

import {
  createUIElement
} from '~/client/assets/js/renderapp/render-data/ui-element/create'

import {
  hasButtonObjectParentElementID,
  getButtonObjectParentElementID,

  hasButtonObjectElementID,
  getButtonObjectElementID,

  hasButtonObjectType,
  getButtonObjectType,

  hasButtonObjectName,
  getButtonObjectName,

  hasButtonObjectGroup,
  getButtonObjectGroup,

  isBloblet,

  hasButtonObjectRange,
  getButtonObjectRange,

  hasButtonObjectThumbnail,
  getButtonObjectThumbnail,

  hasButtonObjectDescription,
  getButtonObjectDescription
} from '~/client/assets/js/renderapp/render-data/button-object'

const log = debug('renderapp:render-data/ui-element-container/create')

log('`renderapp` is awake')

/**
 *  Format simple latin character strings as className compatible
 *
 *  @param {String} s   The string to convert
 *  @return {String}    The string, converted
 */
export const formatClassName = (s) => (
  s.toLowerCase().replace(/[^\w\-\d]/g, ' ').trim().replace(/[\s]+/g, '-').replace(/[\s\s|\-\-]+/g, '-') // eslint-disable-line no-useless-escape
)

export function getUIElementContainerForTypeDropdown (element) {
  /**
   *  log('getUIElementContainerForTypeDropdown')
   */

  while (element !== document.documentElement) {
    if (element.tagName.toLowerCase() === 'select') return element
    element = element.parentElement
  }

  return null
}

export function getUIElementContainer (element) {
  /**
   *  log('getUIElementContainer')
   */

  while (element !== document.documentElement) {
    if (element.tagName.toLowerCase() === 'label') return element
    element = element.parentElement
  }

  return null
}

/**
 *    Create the <img />
 */
export function createUIElementContainerImageChild (buttonObject) {
  /**
   *  log('createUIElementContainerImageChild')
   */

  if (hasButtonObjectThumbnail(buttonObject)) {
    const img = document.createElement('img')
    const alt = `An illustrative image for the configuration option "${getButtonObjectName(buttonObject)}"`
    const src = getButtonObjectThumbnail(buttonObject)

    img.alt = alt
    img.src = src

    return img
  }
}

/**
 *    Create the (required) <span /> to contain the <input />
 */
export function createUIElementContainerInputChild (buttonObject, i) {
  /**
   *  log('createUIElementContainerInputChild')
   */

  const child = document.createElement('span')
  child.className = 'input'

  /**
   *    Create the <input />
   */
  const UIElement = createUIElement(buttonObject, i)

  /**
   *    Assign the <input /> to the configuration object
   */
  buttonObject.UIElement = UIElement

  /**
   *    Append the <input /> child to its parent <span /> container
   */
  child.appendChild(UIElement)

  return child
}

/**
 *    Create the (required) <span /> to contain the name (text)
 */
export function createUIElementContainerNameChild (buttonObject) {
  /**
   *  log('createUIElementContainerNameChild')
   */

  const child = document.createElement('span')
  child.className = 'name'
  child.innerText = hasButtonObjectName(buttonObject)
    ? getButtonObjectName(buttonObject)
    : String.fromCharCode(8203) // zero-width space

  return child
}

/**
 *    Create the (optional) <span /> to contain the description (text)
 */
export function createUIElementContainerDescriptionChildForGroupPack (buttonObject) {
  /**
   *  log('createUIElementContainerDescriptionChildForGroupPack')
   */

  if (hasButtonObjectDescription(buttonObject)) {
    const description = getButtonObjectDescription(buttonObject)

    if (description.includes('¬')) {
      const child = document.createElement('ul')
      child.className = 'description'

      description.split('¬')
        .forEach((html) => {
          const element = document.createElement('li')
          /**
           *    Description may contain HTML escaped characters
           */
          element.innerHTML = html

          child.appendChild(element)
        })

      return child
    }
  }
}

/**
 *    Create the (optional) <span /> to contain the description (text)
 */
export function createUIElementContainerDescriptionChild (buttonObject) {
  /**
   *  log('createUIElementContainerDescriptionChild')
   */

  if (hasButtonObjectDescription(buttonObject)) {
    const description = getButtonObjectDescription(buttonObject)

    const child = document.createElement('span')
    child.className = 'description'
    /**
     *    Description may contain HTML escaped characters
     */
    child.innerHTML = description

    return child
  }
}

/**
 *    Create the (optional) <span /> to contain the price (text)
 */
export function createUIElementContainerPriceChild ({ Prices = [] }) {
  /**
   *  log('createUIElementContainerPriceChild')
   */

  if (Prices.length) {
    const child = document.createElement('span')
    child.className = 'price'
    child.innerText = String.fromCharCode(8203)

    return child
  }
}

export function createRangeColoursParent (buttonObject, parentElement) {
  /**
   *  log('createRangeColoursParent')
   */

  /**
   *    Create a className and assign it to a variable
   */
  const className = formatClassName(getButtonObjectRange(buttonObject))

  let container = parentElement.querySelector(`.range.${className}`)

  if (!container) {
    container = document.createElement('div')
    container.className = `range ${className}`

    const heading = document.createElement('h6')

    heading.appendChild(createTitleChild(buttonObject))
    heading.appendChild(createPriceChild())

    const colours = document.createElement('div')
    colours.className = 'bloblets'

    container.appendChild(heading)
    container.appendChild(colours)
  }

  buttonObject.RangeColoursParent = container

  return container
}

export function createRangeColoursChild (buttonObject, i) {
  /**
   *  log('createRangeColoursChild')
   */

  /**
   *    1. An image
   */
  const image = createUIElementContainerImageChild(buttonObject)

  /**
   *    2. A <span /> to contain an <input /> child
   */
  const input = createUIElementContainerInputChild(buttonObject, i)

  /**
   *    3. A <span /> to contain the "price" text (computed elsewhere)
   */
  const price = createUIElementContainerPriceChild(buttonObject)

  /**
   *    4. A <span /> to contain the "name" text
   */
  const name = createUIElementContainerNameChild(buttonObject)

  /**
   *    5. A <span /> to contain the "description" text
   */
  const description = createUIElementContainerDescriptionChild(buttonObject)

  /**
   *    Assign the trimmed `buttonObject.Name` to variable
   */
  const buttonObjectName = getButtonObjectName(buttonObject)

  /**
   *    Create a <label /> to contain all of the <span /> children
   */
  const UIElementContainer = document.createElement('label')
  UIElementContainer.title = buttonObjectName
  UIElementContainer.className = formatClassName(buttonObjectName)

  /**
   *    Append the image
   */
  if (image) UIElementContainer.appendChild(image)

  /**
   *    Append the price
   */
  if (price) UIElementContainer.appendChild(price)

  /**
   *    Append the input and the name
   */
  UIElementContainer.appendChild(input)
  UIElementContainer.appendChild(name)

  /**
   *    Append the description
   */
  if (description) UIElementContainer.appendChild(description)

  if (hasButtonObjectThumbnail(buttonObject)) UIElementContainer.classList.add('thumbnail')

  buttonObject.UIElementContainer = UIElementContainer

  return UIElementContainer
}

export function createColoursParent (buttonObject, parentElement) {
  /**
   *  log('createColoursParent')
   */

  let container = parentElement.querySelector('.bloblets')

  if (!container) {
    container = document.createElement('div')
    container.className = 'bloblets'
  }

  buttonObject.ColoursParent = container

  return container
}

export function createColoursChild (buttonObject, i) {
  /**
   *  log('createColoursChild')
   */

  /**
   *    1. An image
   */
  const image = createUIElementContainerImageChild(buttonObject)

  /**
   *    2. A <span /> to contain an <input /> child
   */
  const input = createUIElementContainerInputChild(buttonObject, i)

  /**
   *    3. A <span /> to contain the "price" text (computed elsewhere)
   */
  const price = createUIElementContainerPriceChild(buttonObject)

  /**
   *    4. A <span /> to contain the "name" text
   */
  const name = createUIElementContainerNameChild(buttonObject)

  /**
   *    5. A <span /> to contain the "description" text
   */
  const description = createUIElementContainerDescriptionChild(buttonObject)

  /**
   *    Assign the trimmed `buttonObject.Name` to variable
   */
  const buttonObjectName = getButtonObjectName(buttonObject)

  /**
   *    Create a <label /> to contain all of the <span /> children
   */
  const UIElementContainer = document.createElement('label')
  UIElementContainer.title = buttonObjectName
  UIElementContainer.className = formatClassName(buttonObjectName)

  /**
   *    Append the image
   */
  if (image) UIElementContainer.appendChild(image)

  /**
   *    Append the price
   */
  if (price) UIElementContainer.appendChild(price)

  /**
   *    Append the input and the name
   */
  UIElementContainer.appendChild(input)
  UIElementContainer.appendChild(name)

  /**
   *    Append the description
   */
  if (description) UIElementContainer.appendChild(description)

  if (hasButtonObjectThumbnail(buttonObject)) UIElementContainer.classList.add('thumbnail')

  buttonObject.UIElementContainer = UIElementContainer

  return UIElementContainer
}

/**
 *    Create the <span /> to contain the title (text)
 */
export function createTitleChild (buttonObject) {
  /**
   *  log('createTitleChild')
   */

  const child = document.createElement('span')
  child.className = 'title'
  child.innerText = getButtonObjectRange(buttonObject) || String.fromCharCode(8203)

  return child
}

/**
 *    Create the <span /> to contain the price (text)
 */
export function createPriceChild () {
  /**
   *  log('createPriceChild')
   */

  const child = document.createElement('span')
  child.className = 'price'
  child.innerText = String.fromCharCode(8203)

  return child
}

/**
 *    Create the (required) container
 */
export function createUIElementContainerForGroupPack (buttonObject, i) {
  /**
   *  log('createUIElementContainerForGroupPack')
   */

  /**
   *    1. An image
   */
  const image = createUIElementContainerImageChild(buttonObject)

  /**
   *    2. A <span /> to contain an <input /> child
   */
  const input = createUIElementContainerInputChild(buttonObject, i)

  /**
   *    3. A <span /> to contain the "price" text (computed elsewhere)
   */
  const price = createUIElementContainerPriceChild(buttonObject)

  /**
   *    4. A <span /> to contain the "name" text
   */
  const name = createUIElementContainerNameChild(buttonObject)

  /**
   *    Assign the trimmed `buttonObject.Name` to a variable
   */
  const buttonObjectName = getButtonObjectName(buttonObject)

  /**
   *    A. Create a <label /> to contain all of the <span /> children
   */
  const label = document.createElement('label')
  label.title = buttonObjectName
  label.className = formatClassName(buttonObjectName)

  /**
   *    B. Create a <ul /> to contain the "description" text
   */
  const description = createUIElementContainerDescriptionChildForGroupPack(buttonObject)

  /**
   *    Append the image
   */
  if (image) label.appendChild(image)

  /**
   *    Append the price
   */
  if (price) label.appendChild(price)

  /**
   *    Append the input and the name
   */
  label.appendChild(input)
  label.appendChild(name)

  if (hasButtonObjectThumbnail(buttonObject)) label.classList.add('thumbnail')

  const UIElementContainer = document.createElement('div')
  UIElementContainer.className = 'pack'
  UIElementContainer.appendChild(label)
  if (description) UIElementContainer.appendChild(description)

  buttonObject.UIElementContainer = UIElementContainer

  return UIElementContainer
}

/**
 *    Create the (required) container
 */
export function createUIElementContainerForBlobletColours (buttonObject, parentElement, i) {
  /**
   *  log('createUIElementContainerForBlobletColours')
   */

  const coloursParent = createColoursParent(buttonObject, parentElement)

  coloursParent
    .appendChild(createColoursChild(buttonObject, i))

  return coloursParent
}

/**
 *    Create the (required) container
 */
export function createUIElementContainerForBlobletRangeColours (buttonObject, parentElement, i) {
  /**
   *  log('createUIElementContainerForBlobletRangeColours')
   */

  const rangeColoursParent = createRangeColoursParent(buttonObject, parentElement)

  rangeColoursParent.querySelector('.bloblets')
    .appendChild(createRangeColoursChild(buttonObject, i))

  return rangeColoursParent
}

/**
 *    Create the (required) container
 */
export function createUIElementContainer (buttonObject, i) {
  /**
   *  log('createUIElementContainer')
   */

  /**
   *    1. An image
   */
  const image = createUIElementContainerImageChild(buttonObject)

  /**
   *    2. A <span /> to contain an <input /> child
   */
  const input = createUIElementContainerInputChild(buttonObject, i)

  /**
   *    3. A <span /> to contain the "price" text (computed elsewhere)
   */
  const price = createUIElementContainerPriceChild(buttonObject)

  /**
   *    4. A <span /> to contain the "name" text
   */
  const name = createUIElementContainerNameChild(buttonObject)

  /**
   *    5. A <span /> to contain the "description" text
   */
  const description = createUIElementContainerDescriptionChild(buttonObject)

  /**
   *    Assign the trimmed `buttonObject.Name` to variable
   */
  const buttonObjectName = getButtonObjectName(buttonObject)

  /**
   *    Create a <label /> to contain all of the <span /> children
   */
  const UIElementContainer = document.createElement('label')
  UIElementContainer.title = buttonObjectName
  UIElementContainer.className = formatClassName(buttonObjectName)

  /**
   *    Append the image
   */
  if (image) UIElementContainer.appendChild(image)

  /**
   *    Append the price
   */
  if (price) UIElementContainer.appendChild(price)

  /**
   *    Append the input and the name
   */
  UIElementContainer.appendChild(input)
  UIElementContainer.appendChild(name)

  /**
   *    Append the description
   */
  if (description) UIElementContainer.appendChild(description)

  if (hasButtonObjectThumbnail(buttonObject)) UIElementContainer.classList.add('thumbnail')

  buttonObject.UIElementContainer = UIElementContainer

  return UIElementContainer
}

/**
 *    Render the (required) container for the 'dropdown' type
 */
export function renderUIElementContainerForTypeDropdown (buttonObject, i) {
  /**
   *  log('renderUIElementContainerForTypeDropdown')
   */

  if (hasButtonObjectGroup(buttonObject)) {
    const group = getButtonObjectGroup(buttonObject)

    // Get the select by ID
    let UIElementContainer = document.getElementById(group)
    if (!UIElementContainer) { // Create a select and give it an ID
      UIElementContainer = document.createElement('select')
      UIElementContainer.id = group
    }

    const UIElement = createUIElement(buttonObject, i)

    // Option
    buttonObject.UIElement = UIElement

    // Select
    buttonObject.UIElementContainer = UIElementContainer

    // Append the option to the select
    UIElementContainer.appendChild(UIElement)

    return UIElementContainer
  }
}

/**
 *    Update the (required) container
 */
export function updateUIElementContainer (buttonObject, i) {
  /**
   *  log('updateUIElementContainer')
   */

  if (hasButtonObjectElementID(buttonObject)) {
    const id = getButtonObjectElementID(buttonObject)

    const element = document.getElementById(id)
    if (element) {
      element.setAttribute('index', i)
      buttonObject.UIElement = element

      const elementContainer = getUIElementContainer(element)
      if (elementContainer) {
        buttonObject.UIElementContainer = elementContainer
        return
      }

      log('No `UIElementContainer` in `updateUIElementContainer`')
      return
    }

    log(`An element with ID "${id}" was not found in the document`)
    return
  }

  log('No ID in `updateUIElementContainer`')
}

/**
 *    Render the (required) container
 */
export function renderUIElementContainer (buttonObject, i) {
  /**
   *  log('renderUIElementContainer')
   */

  if (hasButtonObjectParentElementID(buttonObject)) {
    const id = getButtonObjectParentElementID(buttonObject)

    const parentElement = document.getElementById(id)
    if (parentElement) {
      /**
       *    An old Jedi mind-trick for improving the performance of DOM element creation:
       *    always append created elements to the DOM at the last moment!
       *
       *    Any change to the current document causes the browser to reflow and repaint,
       *    which is expensive. If you create an element, append it to the document, and
       *    then manipulate the element's attributes, assume that each manipulation (giving
       *    it a `class`, writing some `innerText`, creating its descendant elements and
       *    doing the same with them) will cause the browser to redraw the document. That's
       *    likely to be dozens (if not hundreds or thousands) of expensive processes :(
       *
       *    You can reduce the number of redraws by creating elements and manipulating
       *    them before you append them. An element which is not in the document can't
       *    cause a reflow and repaint! You can do what you like to it!
       *
       *    I've reworked this mechanism so that each step creates or modifies something
       *    but then returns that something to the caller. That way, the caller can decide
       *    if now is a good/appropriate time to append it. As you can see: the child is
       *    created and manipulated without being attached to the document at all, and then ...
       *    plop! Into the document it goes. The document will redraw and repaint. If the
       *    child contains a collection of elements, we've just saved ourselves a lot
       *    of computations :)
       *
       *    (As an addendum: this is much less of an issue for-one off changes to individual
       *    elements in clicks and hovers and other user interactions, but it's still useful
       *    to bear in mind. If one interaction is going to change dozens of elements, then
       *    you will need to consider how to apply them)
       */

      if (hasButtonObjectType(buttonObject)) {
        const type = getButtonObjectType(buttonObject)

        let child
        if (type === 'dropdown') {
          child = renderUIElementContainerForTypeDropdown(buttonObject, i)
        } else {
          if (getButtonObjectGroup(buttonObject) === PACK) {
            child = createUIElementContainerForGroupPack(buttonObject, i)
          } else {
            if (isBloblet(buttonObject)) {
              if (hasButtonObjectRange(buttonObject)) {
                child = createUIElementContainerForBlobletRangeColours(buttonObject, parentElement, i)
              } else {
                child = createUIElementContainerForBlobletColours(buttonObject, parentElement, i)
              }
            } else {
              child = createUIElementContainer(buttonObject, i)
            }
          }
        }

        /**
         *    This still costs a lot, but less than it did
         */
        if (child) parentElement.appendChild(child)
        return
      }

      log('No type in `renderUIElementContainer`')
      return
    }

    log(`An element with ID "${id}" was not found in the document`)
    return
  }

  log('No ID in `renderUIElementContainer`')
}
