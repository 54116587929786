import debug from 'debug'

import renderData, {
  dedupe,
  normalise,
  getConfiguration
} from '~/client/assets/js/common/render-data'

import {
  isSelected,
  isDisabled,
  isEnabled,
  isChanged,

  getIsGroup,

  isGroupModels,
  isGroupPack,
  isGroupPaintColours,
  isGroupSize,

  hasButtonObjectGroup,
  getButtonObjectGroup,

  getIsRange,

  hasButtonObjectRange,
  getButtonObjectRange,

  hasButtonObjectFrontEndRule,
  getButtonObjectFrontEndRule,

  hasButtonObjectBackEndRules,
  getButtonObjectBackEndRules,

  getIsConfigured,
  getIsDefaultConfigured
} from '~/client/assets/js/renderapp/render-data/button-object'

const log = debug('renderapp:render-data/button-objects')

log('`renderapp` is awake')

/**
 *  Get `ButtonObjects`
 */
export function getAllButtonObjects () {
  /**
   *  log('getAllButtonObjects')
   */

  const {
    ButtonObjects = []
  } = renderData

  return ButtonObjects
}

/**
 *  Group
 */

/**
 *  Returns `[group, group, group]`
 */
export function getGroupsFromButtonObjects () {
  /**
   *  log('getGroupsFromButtonObjects')
   */

  return (
    getAllButtonObjects()
      .filter(hasButtonObjectGroup)
      .map(getButtonObjectGroup)
      .reduce(dedupe, [])
  )
}

/**
 *  Returns `[group, group, group]`
 */
export function getGroupsFrom (buttonObjects = []) {
  /**
   *  log('getGroupsFrom')
   */

  return (
    buttonObjects
      .filter(hasButtonObjectGroup)
      .map(getButtonObjectGroup)
      .reduce(dedupe, [])
  )
}

/**
 *  Returns `[buttonObject, buttonObject, buttonObject]`
 */
export function getAllButtonObjectsHasGroup () {
  /**
   *  log('getAllButtonObjectsHasGroup')
   */

  return (
    getAllButtonObjects()
      .filter(hasButtonObjectGroup)
  )
}

/**
 *  Returns `[buttonObject, buttonObject, buttonObject]`
 */
export function getAllButtonObjectsForGroup (group) {
  /**
   *  log('getAllButtonObjectsHasGroup')
   */

  if (!group) return []

  const isGroup = getIsGroup(group)

  return (
    getAllButtonObjectsHasGroup()
      .filter(isGroup)
  )
}

/**
 *  Group
 */

export function hasButtonObjectsForGroup (group, buttonObjects) {
  /**
   *  log('hasButtonObjectsForGroup')
   */

  if (!group) return false

  const isGroup = getIsGroup(group)

  return (
    (buttonObjects || getAllButtonObjectsHasGroup())
      .some(isGroup)
  )
}

export function getButtonObjectsForGroup (group, buttonObjects) {
  /**
   *  log('getButtonObjectsForGroup')
   */

  if (!group) return []

  const isGroup = getIsGroup(group)

  return (
    (buttonObjects || getAllButtonObjectsHasGroup())
      .filter(isGroup)
  )
}

export function hasButtonObjectsForGroupModels (buttonObjects = getAllButtonObjectsHasGroup()) {
  /**
   *  log('hasButtonObjectsForGroupModels')
   */

  return (
    buttonObjects
      .some(isGroupModels)
  )
}

export function getButtonObjectsForGroupModels (buttonObjects = getAllButtonObjectsHasGroup()) {
  /**
   *  log('getButtonObjectsForGroupModels')
   */

  return (
    buttonObjects
      .filter(isGroupModels)
  )
}

export function hasButtonObjectsForGroupPack (buttonObjects = getAllButtonObjectsHasGroup()) {
  /**
   *  log('hasButtonObjectsForGroupPack')
   */

  return (
    buttonObjects
      .some(isGroupPack)
  )
}

export function getButtonObjectsForGroupPack (buttonObjects = getAllButtonObjectsHasGroup()) {
  /**
   *  log('getButtonObjectsForGroupPack')
   */

  return (
    buttonObjects
      .filter(isGroupPack)
  )
}

export function hasButtonObjectsForGroupPaintColours (buttonObjects = getAllButtonObjectsHasGroup()) {
  /**
   *  log('hasButtonObjectsForGroupPaintColours')
   */

  return (
    buttonObjects
      .some(isGroupPaintColours)
  )
}

export function getButtonObjectsForGroupPaintColours (buttonObjects = getAllButtonObjectsHasGroup()) {
  /**
   *  log('getButtonObjectsForGroupPaintColours')
   */

  return (
    buttonObjects
      .filter(isGroupPaintColours)
  )
}

export function hasButtonObjectsForGroupChassisSize (buttonObjects = getAllButtonObjectsHasGroup()) {
  /**
   *  log('hasButtonObjectsForGroupChassisSize')
   */

  return (
    buttonObjects
      .some(isGroupSize)
  )
}

export function getButtonObjectsForGroupChassisSize (buttonObjects = getAllButtonObjectsHasGroup()) {
  /**
   *  log('getButtonObjectsForGroupChassisSize')
   */

  return (
    buttonObjects
      .filter(isGroupSize)
  )
}

/**
 *  Has at least one (Any Enabled)
 */
export function hasAnyButtonObjectsEnabledForGroup (group) {
  /**
   *  log('hasAnyButtonObjectsEnabledForGroup')
   */

  const buttonObjects = getAllButtonObjectsForGroup(group)

  return (
    buttonObjects
      .some(isEnabled)
  )
}

/**
 *  Has at least one (Any Selected)
 */
export function hasAnyButtonObjectsSelectedForGroup (group) {
  /**
   *  log('hasAnyButtonObjectsSelectedForGroup')
   */

  const buttonObjects = getAllButtonObjectsForGroup(group)

  return (
    buttonObjects
      .some(isSelected)
  )
}

/**
 *  Has at least one (Any Disabled)
 */
export function hasAnyButtonObjectsDisabledForGroup (group) {
  /**
   *  log('hasAnyButtonObjectsDisabledForGroup')
   */

  const buttonObjects = getAllButtonObjectsForGroup(group)

  return (
    buttonObjects
      .some(isDisabled)
  )
}

/**
 *  Has at least one (Any Changed)
 */
export function hasAnyButtonObjectsChangedForGroup (group) {
  /**
   *  log('hasAnyButtonObjectsChangedForGroup')
   */

  const buttonObjects = getAllButtonObjectsForGroup(group)

  return (
    buttonObjects
      .some(isChanged)
  )
}

/**
 *  Has every (All Enabled)
 */
export function hasAllButtonObjectsEnabledForGroup (group) {
  /**
   *  log('hasAllButtonObjectsEnabledForGroup')
   */

  if (!group) return false

  const buttonObjects = getAllButtonObjectsForGroup(group)

  return (
    buttonObjects
      .every(isEnabled)
  )
}

/**
 *  Has every (All Selected)
 */
export function hasAllButtonObjectsSelectedForGroup (group) {
  /**
   *  log('hasAllButtonObjectsSelectedForGroup')
   */

  if (!group) return false

  const buttonObjects = getAllButtonObjectsForGroup(group)

  return (
    buttonObjects
      .every(isSelected)
  )
}

/**
 *  Has every (All Disabled)
 */
export function hasAllButtonObjectsDisabledForGroup (group) {
  /**
   *  log('hasAllButtonObjectsDisabledForGroup')
   */

  if (!group) return false

  const buttonObjects = getAllButtonObjectsForGroup(group)

  return (
    buttonObjects
      .every(isDisabled)
  )
}

/**
 *  Has every (All Changed)
 */
export function hasAllButtonObjectsChangedForGroup (group) {
  /**
   *  log('hasAllButtonObjectsChangedForGroup')
   */

  if (!group) return false

  const buttonObjects = getAllButtonObjectsForGroup(group)

  return (
    buttonObjects
      .every(isChanged)
  )
}

/**
 *  Get all (Enabled)
 */
export function getButtonObjectsEnabledForGroup (group, buttonObjects) {
  /**
   *  log('getButtonObjectsEnabledForGroup')
   */

  if (!group) return []

  return (
    (buttonObjects || getAllButtonObjectsForGroup(group))
      .filter(isEnabled)
  )
}

/**
 *  Get all (Selected)
 */
export function getButtonObjectsSelectedForGroup (group, buttonObjects) {
  /**
   *  log('getButtonObjectsSelectedForGroup')
   */

  if (!group) return []

  return (
    (buttonObjects || getAllButtonObjectsForGroup(group))
      .filter(isSelected)
  )
}

/**
 *  Get all (Disabled)
 */
export function getButtonObjectsDisabledForGroup (group, buttonObjects) {
  /**
   *  log('getButtonObjectsDisabledForGroup')
   */

  if (!group) return []

  return (
    (buttonObjects || getAllButtonObjectsForGroup(group))
      .filter(isDisabled)
  )
}

/**
 *  Get all (Changed)
 */
export function getButtonObjectsChangedForGroup (group, buttonObjects) {
  /**
   *  log('getButtonObjectsChangedForGroup')
   */

  if (!group) return []

  return (
    (buttonObjects || getAllButtonObjectsForGroup(group))
      .filter(isChanged)
  )
}

/**
 *  Range
 */

/**
 *  Returns `[range, range, range]`
 */
export function getRangesFromButtonObjects () {
  /**
   *  log('getRangesFromButtonObjects')
   */

  return (
    getAllButtonObjects()
      .filter(hasButtonObjectRange)
      .map(getButtonObjectRange)
      .reduce(dedupe, [])
  )
}

/**
 *  Returns `[range, range, range]`
 */
export function getRangesFrom (buttonObjects = []) {
  /**
   *  log('getRangesFrom')
   */

  return (
    buttonObjects
      .filter(hasButtonObjectRange)
      .map(getButtonObjectRange)
      .reduce(dedupe, [])
  )
}

/**
 *  Returns `[buttonObject, buttonObject, buttonObject]`
 */
export function getAllButtonObjectsHasRange () {
  /**
   *  log('getAllButtonObjectsHasRange')
   */

  return (
    getAllButtonObjects()
      .filter(hasButtonObjectRange)
  )
}

/**
 *  Returns `[buttonObject, buttonObject, buttonObject]`
 */
export function getAllButtonObjectsForRange (range) {
  /**
   *  log('getAllButtonObjectsForRange')
   */

  if (!range) return []

  const isRange = getIsRange(range)

  return (
    getAllButtonObjectsHasRange()
      .filter(isRange)
  )
}

/**
 *  Range
 */

export function hasButtonObjectsForRange (range, buttonObjects) {
  /**
   *  log('hasButtonObjectsForRange')
   */

  if (!range) return false

  const isRange = getIsRange(range)

  return (
    (buttonObjects || getAllButtonObjectsHasRange())
      .some(isRange)
  )
}

export function getButtonObjectsForRange (range, buttonObjects) {
  /**
   *  log('getButtonObjectsForRange')
   */

  if (!range) return []

  const isRange = getIsRange(range)

  return (
    (buttonObjects || getAllButtonObjectsHasRange())
      .filter(isRange)
  )
}

/**
 *  Has at least one (Any Enabled)
 */
export function hasAnyButtonObjectsEnabledForRange (range) {
  /**
   *  log('hasAnyButtonObjectsEnabledForRange')
   */

  if (!range) return false

  const buttonObjects = getAllButtonObjectsForRange(range)

  return (
    buttonObjects
      .some(isEnabled)
  )
}

/**
 *  Has at least one (Any Selected)
 */
export function hasAnyButtonObjectsSelectedForRange (range) {
  /**
   *  log('hasAnyButtonObjectsSelectedForRange')
   */

  if (!range) return false

  const buttonObjects = getAllButtonObjectsForRange(range)

  return (
    buttonObjects
      .some(isSelected)
  )
}

/**
 *  Has at least one (Any Disabled)
 */
export function hasAnyButtonObjectsDisabledForRange (range) {
  /**
   *  log('hasAnyButtonObjectsDisabledForRange')
   */

  if (!range) return false

  const buttonObjects = getAllButtonObjectsForRange(range)

  return (
    buttonObjects
      .some(isDisabled)
  )
}

/**
 *  Has at least one (Any Changed)
 */
export function hasAnyButtonObjectsChangedForRange (range) {
  /**
   *  log('hasAnyButtonObjectsChangedForRange')
   */

  if (!range) return false

  const buttonObjects = getAllButtonObjectsForRange(range)

  return (
    buttonObjects
      .some(isChanged)
  )
}

/**
 *  Has every (All Enabled)
 */
export function hasAllButtonObjectsEnabledForRange (range) {
  /**
   *  log('hasAllButtonObjectsEnabledForRange')
   */

  if (!range) return false

  const buttonObjects = getAllButtonObjectsForRange(range)

  return (
    buttonObjects
      .every(isEnabled)
  )
}

/**
 *  Has every (All Selected)
 */
export function hasAllButtonObjectsSelectedForRange (range) {
  /**
   *  log('hasAllButtonObjectsSelectedForRange')
   */

  if (!range) return false

  const buttonObjects = getAllButtonObjectsForRange(range)

  return (
    buttonObjects
      .every(isSelected)
  )
}

/**
 *  Has every (All Disabled)
 */
export function hasAllButtonObjectsDisabledForRange (range) {
  /**
   *  log('hasAllButtonObjectsDisabledForRange')
   */

  if (!range) return false

  const buttonObjects = getAllButtonObjectsForRange(range)

  return (
    buttonObjects
      .every(isDisabled)
  )
}

/**
 *  Has every (All Changed)
 */
export function hasAllButtonObjectsChangedForRange (range) {
  /**
   *  log('hasAllButtonObjectsChangedForRange')
   */

  if (!range) return false

  const buttonObjects = getAllButtonObjectsForRange(range)

  return (
    buttonObjects
      .every(isChanged)
  )
}

/**
 *  Get all (Enabled)
 */
export function getButtonObjectsEnabledForRange (range, buttonObjects) {
  /**
   *  log('getButtonObjectsEnabledForRange')
   */

  if (!range) return []

  return (
    (buttonObjects || getAllButtonObjectsForRange(range))
      .filter(isEnabled)
  )
}

/**
 *  Get all (Selected)
 */
export function getButtonObjectsSelectedForRange (range, buttonObjects) {
  /**
   *  log('getButtonObjectsSelectedForRange')
   */

  if (!range) return []

  return (
    (buttonObjects || getAllButtonObjectsForRange(range))
      .filter(isSelected)
  )
}

/**
 *  Get all (Disabled)
 */
export function getButtonObjectsDisabledForRange (range, buttonObjects) {
  /**
   *  log('getButtonObjectsDisabledForRange')
   */

  if (!range) return []

  return (
    (buttonObjects || getAllButtonObjectsForRange(range))
      .filter(isDisabled)
  )
}

/**
 *  Get all (Changed)
 */
export function getButtonObjectsChangedForRange (range, buttonObjects) {
  /**
   *  log('getButtonObjectsChangedForRange')
   */

  if (!range) return []

  return (
    (buttonObjects || getAllButtonObjectsForRange(range))
      .filter(isChanged)
  )
}

/**
 *  From `ButtonObjects`
 */

export function reduceConfigurationFromButtonObjects (accumulator, buttonObject) {
  /**
   *  log('reduceConfigurationFromButtonObjects')
   */

  if (hasButtonObjectFrontEndRule(buttonObject)) {
    return (
      accumulator.concat(getButtonObjectFrontEndRule(buttonObject))
    )
  }

  return accumulator
}

export function reduceBackendConfigurationFromButtonObjects (accumulator, buttonObject) {
  /**
   *  log('reduceBackendConfigurationFromButtonObjects')
   */

  if (hasButtonObjectBackEndRules(buttonObject)) {
    return (
      accumulator.concat(getButtonObjectBackEndRules(buttonObject))
    )
  }

  return accumulator
}

export function reduceDefaultConfigurationFromButtonObjects (accumulator, buttonObject) {
  /**
   *  log('reduceDefaultConfigurationFromButtonObjects')
   */

  if (hasButtonObjectFrontEndRule(buttonObject)) {
    return (
      accumulator.concat(getButtonObjectFrontEndRule(buttonObject))
    )
  }

  return accumulator
}

export function getConfigurationFromButtonObjects () {
  /**
   *  log('getConfigurationFromButtonObjects')
   */

  const buttonObjects = getAllButtonObjects()

  return normalise(
    buttonObjects
      .filter(isSelected)
      .filter(isEnabled)
      .reduce(reduceConfigurationFromButtonObjects, '')
  )
}

export function getBackendConfigurationFromButtonObjects (configuration = getConfiguration()) {
  /**
   *  log('getBackendConfigurationFromButtonObjects')
   */

  const buttonObjects = getAllButtonObjects()

  return normalise(
    buttonObjects
      .filter(getIsConfigured(configuration)) // `isConfigured`
      .reduce(reduceBackendConfigurationFromButtonObjects, '')
  )
}

export function getDefaultConfigurationFromButtonObjects (configuration = '+S6H') {
  /**
   *  log('getDefaultConfigurationFromButtonObjects')
   */

  const buttonObjects = getAllButtonObjects()

  return normalise(
    buttonObjects
      .filter(getIsDefaultConfigured(configuration))
      .reduce(reduceDefaultConfigurationFromButtonObjects, '')
  )
}
