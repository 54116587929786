import debug from 'debug'

import renderModelLogo from './model-logo'

import renderPriceSummary from './price-summary'

import renderOptionsPreview from './options-preview'

import renderOptionsSummary from './options-summary'

const log = debug('renderapp:render-data/ui')

log('`renderapp` is awake')

export default function render () {
  log('render')

  renderModelLogo()

  renderPriceSummary()

  renderOptionsPreview()

  renderOptionsSummary()
}
