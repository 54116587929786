import debug from 'debug'

import {
  isVertical
} from '~/client/assets/js/common/device'

import MD5 from '~/client/assets/js/renderapp/md5'

import {
  WEBGL,
  REVIEW
} from '~/client/assets/js/common/render-data'

const log = debug('renderapp:render-data/camera-image')

log('`renderapp` is awake')

const {
  Image = class Image {}
} = global

export const DEFAULT_CAMERA_IMAGE_SRC = '/assets/img/default.png'
export const DEFAULT_CAMERA_IMAGE_ALT = 'An illustrative image of your configuration'

export const DEFAULT_CAMERA_IMAGE_CACHE = new Image()
export const CURRENT_CAMERA_IMAGE_CACHE = new Image()

export const DEFAULT_CAMERA_IMAGE_NAME_CACHE = new Set()
export const CURRENT_CAMERA_IMAGE_NAME_CACHE = new Set()

export function createDefaultCameraImageFromCache () {
  /**
   *  log('createDefaultCameraImageFromCache')
   */

  const {
    src,
    alt
  } = DEFAULT_CAMERA_IMAGE_CACHE

  return createCameraImage(src, alt)
}

export function createCurrentCameraImageFromCache () {
  /**
   *  log('createCurrentCameraImageFromCache')
   */

  const {
    src,
    alt
  } = CURRENT_CAMERA_IMAGE_CACHE

  return createCameraImage(src, alt)
}

export function createCameraImage (src = DEFAULT_CAMERA_IMAGE_SRC, alt = DEFAULT_CAMERA_IMAGE_ALT) {
  /**
   *  log('createCameraImage')
   */

  const img = document.createElement('img')

  img.alt = alt
  img.src = src

  return img
}

export function createWebGLCameraExteriorImageName (image) {
  /**
   *  log('createWebGLCameraExteriorImageName')
   */

  const {
    Width,
    Height,
    Position = {},
    Rotation = {},
    Configuration
  } = image

  return `${Width}_${Height}_${Position.Z}_${Rotation.X}_${Rotation.Y}_${Rotation.Z}_${Configuration}`
}

export function createWebGLCameraInteriorImageName (image) {
  /**
   *  log('createWebGLCameraInteriorImageName')
   */

  const {
    Is360
  } = image

  if (Is360) {
    const {
      Width,
      Height,
      Configuration
    } = image

    return `${Width}_${Height}_${Configuration}`
  } else {
    const {
      Width,
      Height,
      Index,
      Fov,
      Rotation = {},
      Configuration
    } = image

    return `${Width}_${Height}_${Index}_${Fov}_${Rotation.X}_${Rotation.Y}_${Rotation.Z}_${Configuration}`
  }
}

export function createReviewCameraImageName ({ Width, Height, Fov, Position = {}, RotationQ = {}, Configuration }) {
  /**
   *  log('createReviewCameraImageName')
   */

  return `${Width}_${Height}_${Fov}_${Position.X}_${Position.Y}_${Position.Z}_${RotationQ.X}_${RotationQ.Y}_${RotationQ.Z}_${RotationQ.W}_${Configuration}`
}

export function createCameraRotationQImageName ({ Width, Height, Fov, Position = {}, RotationQ = {}, Configuration }) {
  /**
   *  log('createCameraRotationQImageName')
   */

  return `${Width}_${Height}_${Fov}_${Position.X}_${Position.Y}_${Position.Z}_${RotationQ.X}_${RotationQ.Y}_${RotationQ.Z}_${RotationQ.W}_${Configuration}_${isVertical()}`
}

export function createCameraDirectionImageName ({ Width, Height, Fov, Position = {}, Direction = {}, Configuration }) {
  /**
   *  log('createCameraDirectionImageName')
   */

  return `${Width}_${Height}_${Fov}_${Position.X}_${Position.Y}_${Position.Z}_${Direction.X}_${Direction.Y}_${Direction.Z}_${Configuration}_${isVertical()}`
}

export function createCameraImageName (image = {}) {
  /**
   *  log('createCameraImageName')
   */

  let imageName = ''

  const {
    Type
  } = image

  if (Type === WEBGL) {
    const {
      CameraMode
    } = image

    if (CameraMode === 'exterior') {
      imageName = createWebGLCameraExteriorImageName(image)
    } else {
      imageName = createWebGLCameraInteriorImageName(image)
    }
  } else {
    if (Type === REVIEW) {
      imageName = createReviewCameraImageName(image)
    } else {
      const {
        RotationType
      } = image

      if (RotationType === 'free') {
        imageName = createCameraRotationQImageName(image)
      } else {
        imageName = createCameraDirectionImageName(image)
      }
    }
  }

  return MD5(imageName)
}
