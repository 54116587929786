import debug from 'debug'

import {
  getBasePrice,
  getOptionsPrice,
  renderTotalPrice
} from '~/client/assets/js/common/render-data'

import getPriceFormat from '~/client/assets/js/renderapp/render-data/price-format'

/**
 *  Options Summary
 **/

const log = debug('renderapp:render-data/ui/price-summary')

log('`renderapp` is awake')

export default function render () {
  log('render')

  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  requestAnimationFrame(() => {
    const basePrice = getBasePrice()
    const optionsPrice = getOptionsPrice()
    const totalPrice = renderTotalPrice(basePrice, optionsPrice)

    const priceFormat = getPriceFormat(2)

    const formattedBasePrice = priceFormat.format(basePrice)
    const formattedOptionsPrice = priceFormat.format(optionsPrice)
    const formattedTotalPrice = priceFormat.format(totalPrice)

    /**
     *  There may be more than one parent "Price summary" in the document
     */
    document.querySelectorAll('#renderapp .price-summary')
      /**
       *  There will not be more than one of each child per "Price summary"
       */
      .forEach((priceSummary) => {
        const basePrice = priceSummary.querySelector('.base-price .price')
        if (basePrice) basePrice.innerText = formattedBasePrice

        const optionsPrice = priceSummary.querySelector('.options-price .price')
        if (optionsPrice) optionsPrice.innerText = formattedOptionsPrice

        const totalPrice = priceSummary.querySelector('.total-price .price')
        if (totalPrice) totalPrice.innerText = formattedTotalPrice
      })
  })
}
