import debug from 'debug'

let LOADING
let TIMEOUT = 0

const log = debug('renderapp:render-data/ui/loading')

log('`renderapp` is awake')

// Show Loading
function show () {
  /**
   *  log('show')
   */

  (LOADING || (LOADING = document.getElementById('loading')))
    .className = 'show'

  return (TIMEOUT = 0)
}

// Hide Loading
function hide () {
  /**
   *  log('hide')
   */

  (LOADING || (LOADING = document.getElementById('loading')))
    .className = 'hide'

  return (TIMEOUT = 0)
}

export function resetTimeout () {
  /**
   *  log('resetTimeout')
   */

  if (TIMEOUT) clearTimeout(TIMEOUT)

  return (TIMEOUT = 0)
}

export function createShowTimeout () {
  /**
   *  log('createShowTimeout')
   */

  return (TIMEOUT = setTimeout(show, 500))
}

export function createHideTimeout () {
  /**
   *  log('createHideTimeout')
   */

  return (TIMEOUT = setTimeout(hide, 500))
}
