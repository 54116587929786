import debug from 'debug'

import {
  isSelected,

  getButtonObjectName,
  getButtonObjectLogo
} from '~/client/assets/js/renderapp/render-data/button-object'

import {
  getButtonObjectsForGroupModels
} from '~/client/assets/js/renderapp/render-data/button-objects'

const log = debug('renderapp:render-data/ui/model-logo')

log('`renderapp` is awake')

export default function render () {
  log('render')

  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  requestAnimationFrame(() => {
    const buttonObjects = getButtonObjectsForGroupModels()

    if (buttonObjects.some(isSelected)) {
      const buttonObject = buttonObjects.find(isSelected)

      const name = getButtonObjectName(buttonObject)
      const logo = getButtonObjectLogo(buttonObject)

      const alt = `A logo for the model "${name}"`
      const src = logo

      document.querySelectorAll('#renderapp .model-logo img')
        .forEach((img) => {
          img.alt = alt
          img.src = src
        })
    }
  })
}
