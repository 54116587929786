import debug from 'debug'

import {
  createDefaultCameraImageFromCache
} from '~/client/assets/js/renderapp/render-data/camera-image'

/**
 *  Options Preview
 **/

const log = debug('renderapp:render-data/ui/options-preview')

log('`renderapp` is awake')

export default function render () {
  log('render')

  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  requestAnimationFrame(() => {
    document.querySelectorAll('#renderapp .options-preview')
      /**
       *  There may be more than one parent "Options preview" in the document
       */
      .forEach((optionsPreview) => {
        optionsPreview
          .querySelectorAll('img')
          .forEach((img) => {
            optionsPreview.removeChild(img)
          })

        optionsPreview.appendChild(createDefaultCameraImageFromCache())
      })
  })
}
