import debug from 'debug'

import {
  getCameras,
  getCamera
} from '~/client/assets/js/common/render-data'

const log = debug('renderapp:render-data/cameras')

log('`renderapp` is awake')

export function isConfigurationForSmallChassis (configuration = '') {
  /**
   *  log('isConfigurationForSmallChassis')
   */

  return (
    configuration.includes('+SZ3')
  )
}

export function isConfigurationForLargeChassis (configuration = '') {
  /**
   *  log('isConfigurationForLargeChassis')
   */

  return (
    configuration.includes('+SZV')
  )
}

export function getCamerasForSmallChassis () {
  /**
   *  log('getCamerasForSmallChassis')
   */

  const cameras = getCameras()

  return cameras.filter(({ Name }) => /_s3_/.test(Name))
}

export function getCamerasForLargeChassis () {
  /**
   *  log('getCamerasForLargeChassis')
   */

  const cameras = getCameras()

  return cameras.filter(({ Name }) => /_sv_/.test(Name))
}

export function getCamerasFor (configuration) {
  /**
   *  log('getCamerasFor')
   */

  let cameras

  if (isConfigurationForSmallChassis(configuration)) {
    cameras = getCamerasForSmallChassis()
  }

  if (isConfigurationForLargeChassis(configuration)) {
    cameras = getCamerasForLargeChassis()
  }

  return cameras || []
}

export function getCurrentCamera (configuration) {
  /**
   *  log('getCurrentCamera')
   */

  const cameras = getCamerasFor(configuration)

  return cameras[
    getCamera()
  ]
}

export function getDefaultCamera (configuration) {
  /**
   *  log('getDefaultCamera')
   */

  const [
    camera
  ] = getCamerasFor(configuration)

  return camera
}
