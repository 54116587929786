import debug from 'debug'

import {
  hasButtonObjectType,
  getButtonObjectType,

  hasButtonObjectName,
  getButtonObjectName,

  hasButtonObjectGroup,
  getButtonObjectGroup
} from '~/client/assets/js/renderapp/render-data/button-object'

const log = debug('renderapp:render-data/ui-element/create')

log('`renderapp` is awake')

export function createUIElement (buttonObject, index) {
  /**
   *  log('createUIElement')
   */

  if (hasButtonObjectType(buttonObject)) {
    const type = getButtonObjectType(buttonObject)

    if (type === 'dropdown') {
      const option = document.createElement('option')
      option.setAttribute('index', index)

      if (hasButtonObjectName(buttonObject)) {
        option.innerText = getButtonObjectName(buttonObject)
      }

      return option
    }

    if (type === 'checkbox' || type === 'radio') {
      const input = document.createElement('input')
      input.setAttribute('index', index)
      input.type = type

      if (type === 'radio') {
        if (hasButtonObjectGroup(buttonObject)) {
          input.name = getButtonObjectGroup(buttonObject)
        }
      }

      return input
    }
  }

  return null
}
