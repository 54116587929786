import debug from 'debug'

import {
  hasButtonObjectUIElement,
  getButtonObjectUIElement,

  hasButtonObjectType,
  getButtonObjectType
} from '~/client/assets/js/renderapp/render-data/button-object'

const log = debug('renderapp:render-data/ui-element/change')

log('`renderapp` is awake')

/**
 *  `UIElement`
 */

export function updateUIElementDisabled (buttonObject, state = true) {
  /**
   *  log('updateUIElementDisabled')
   */

  if (hasButtonObjectUIElement(buttonObject)) {
    const UIElement = getButtonObjectUIElement(buttonObject)

    UIElement.disabled = state
  }
}

export function updateUIElementSelected (buttonObject, state = true) {
  /**
   *  log('updateUIElementSelected')
   */

  if (hasButtonObjectUIElement(buttonObject)) {
    const UIElement = getButtonObjectUIElement(buttonObject)

    if (hasButtonObjectType(buttonObject)) {
      const type = getButtonObjectType(buttonObject)

      if (type === 'dropdown') {
        UIElement.selected = true
      } else if (type === 'radio' || type === 'checkbox') {
        UIElement.checked = true
      }
    }
  }
}
