import debug from 'debug'

import evaluate from 'render-script'

import {
  MODELS,
  PACK,
  PAINTCOLOURS,
  SIZE,
  ENVIRONMENTS,
  CAMERAS
} from '~/client/assets/js/common/render-data'

const log = debug('renderapp:render-data/button-object')

log('`renderapp` is awake')

const isTruthy = (v) => !!((v || '').trim())

const toRule = (v) => '+'.concat(v)

export const isVisible = ({ Visibility = false }) => Boolean(Visibility)

export function isSelected ({ Type, UIElement = {} }) {
  if (Type === 'dropdown') {
    return UIElement.selected || false
  }

  if (Type === 'radio' || Type === 'checkbox') {
    return UIElement.checked || false
  }

  return false
}

export const isDisabled = ({ UIElement: { disabled = false } = {} }) => disabled

export const isEnabled = ({ UIElement: { disabled = false } = {} }) => !disabled

export const isChecked = ({ Checked = false }) => Boolean(Checked)

export const isChanged = ({ Changed = false }) => Boolean(Changed)

export const isBloblet = ({ Bloblet = false }) => Boolean(Bloblet)

export function isDisabledForConfiguration (buttonObject, configuration) {
  /**
   *   log('isDisabledForConfiguration')
   */

  if (hasButtonObjectVisibilityRule(buttonObject)) {
    const visibilityRule = getButtonObjectVisibilityRule(buttonObject)

    return !( // `false` means `not visible` means `disabled`
      evaluate(visibilityRule, configuration)
    )
  }

  return false // never `disabled`
}

/**
 *  Field is `ParentElement`
 */
export const hasButtonObjectParentElementID = (buttonObject) => !!(getButtonObjectParentElementID(buttonObject))

/**
 *  Field is `ParentElement`
 */
export const getButtonObjectParentElementID = ({ ParentElement }) => (ParentElement || '').trim()

/**
 *  Field is `FrontEndRule`
 */
export const hasButtonObjectElementID = (buttonObject) => !!(getButtonObjectElementID(buttonObject))

/**
 *  Field is `FrontEndRule`
 */
export const getButtonObjectElementID = ({ FrontEndRule }) => (FrontEndRule || '').trim()

export const hasButtonObjectType = (buttonObject) => !!(getButtonObjectType(buttonObject))

export const getButtonObjectType = ({ Type }) => (Type || '').trim()

export const hasButtonObjectName = (buttonObject) => !!(getButtonObjectName(buttonObject))

export const getButtonObjectName = ({ Name }) => (Name || '').trim()

export const hasButtonObjectDescription = (buttonObject) => !!(getButtonObjectDescription(buttonObject))

export const getButtonObjectDescription = ({ Description }) => (Description || '').trim()

export const hasButtonObjectThumbnail = (buttonObject) => !!(getButtonObjectThumbnail(buttonObject))

export const getButtonObjectThumbnail = ({ Thumbnail }) => (Thumbnail || '').trim()

export const hasButtonObjectLogo = (buttonObject) => !!(getButtonObjectLogo(buttonObject))

export const getButtonObjectLogo = ({ Logo }) => (Logo || '').trim()

export const hasButtonObjectGroup = (buttonObject) => !!(getButtonObjectGroup(buttonObject))

export const getButtonObjectGroup = ({ Group }) => (Group || '').trim()

export function getIsGroup (group) {
  /**
   *  log('getIsGroup')
   */

  return function isGroup (buttonObject) {
    /**
     *  log('isGroup')
     */

    return (
      group === getButtonObjectGroup(buttonObject)
    )
  }
}

export const isGroupModels = getIsGroup(MODELS)

export const isGroupPack = getIsGroup(PACK)

export const isGroupPaintColours = getIsGroup(PAINTCOLOURS)

export const isGroupSize = getIsGroup(SIZE)

export const isGroupEnvironments = getIsGroup(ENVIRONMENTS)

export const isGroupCameras = getIsGroup(CAMERAS)

export const hasButtonObjectRange = (buttonObject) => !!(getButtonObjectRange(buttonObject))

export const getButtonObjectRange = ({ Range }) => (Range || '').trim()

export function getIsRange (range) {
  /**
   *  log('getIsRange')
   */

  return function isRange (buttonObject) {
    /**
     *  log('isRange')
     */

    return (
      range === getButtonObjectRange(buttonObject)
    )
  }
}

export const hasButtonObjectVisibilityRule = (buttonObject) => !!(getButtonObjectVisibilityRule(buttonObject))

export const getButtonObjectVisibilityRule = ({ VisibilityRule }) => (VisibilityRule || '').trim()

export const hasButtonObjectFrontEndRule = (buttonObject) => !!(getButtonObjectFrontEndRule(buttonObject))

export const getButtonObjectFrontEndRule = ({ FrontEndRule }) => (FrontEndRule || '').trim()

export const hasButtonObjectBackEndRules = (buttonObject) => !!(getButtonObjectBackEndRules(buttonObject))

export const getButtonObjectBackEndRules = ({ BackEndRules }) => (BackEndRules || '').trim()

export const hasButtonObjectPairWithRule = (buttonObject) => !!(getButtonObjectPairWithRule(buttonObject))

export const getButtonObjectPairWithRule = ({ PairWithRule }) => (PairWithRule || '').trim()

export const hasButtonObjectDefaultRule = (buttonObject) => !!(getButtonObjectDefaultRule(buttonObject))

export const getButtonObjectDefaultRule = ({ DefaultRule }) => (DefaultRule || '').trim()

export const hasButtonObjectRangeColoursParent = (buttonObject) => !!(getButtonObjectRangeColoursParent(buttonObject))

export const getButtonObjectRangeColoursParent = ({ RangeColoursParent }) => RangeColoursParent

export const hasButtonObjectUIElementContainer = (buttonObject) => !!(getButtonObjectUIElementContainer(buttonObject))

export const getButtonObjectUIElementContainer = ({ UIElementContainer }) => UIElementContainer

export const hasButtonObjectUIElement = (buttonObject) => !!(getButtonObjectUIElement(buttonObject))

export const getButtonObjectUIElement = ({ UIElement }) => UIElement

export const hasButtonObjectPrices = (buttonObject) => !!(getButtonObjectPrices(buttonObject).length)

export const getButtonObjectPrices = ({ Prices = [] }) => Prices

/**
 *  A `BasePrice` of zero, undefined, or NaN is acceptable
 *
 *  `Reflect.has()` will tell us whether the field is defined on the object and does not care what the value is (or its type)
 */
export const hasButtonObjectBasePrice = (buttonObject) => Reflect.has(buttonObject, 'BasePrice')

/**
 *  A `BasePrice` of zero, undefined, or NaN is acceptable
 *
 *  `Reflect.get()` returns the value of the field or undefined if the field is undefined
 */
export const getButtonObjectBasePrice = (buttonObject) => Reflect.get(buttonObject, 'BasePrice')

export function getButtonObjectPrice (buttonObject, priceColumn = 0) {
  /**
   *  log('getButtonObjectPrice')
   */

  /**
   *  `BasePrice` is a price column index. Some values are numbers and some are undefined. During initialisation these values are transformed
   *  with `parseFloat` to a number or to NaN
   */
  const basePrice = getButtonObjectBasePrice(buttonObject)

  /**
   *  `Prices` is an array created during initialisation. Some values are numbers and some are the string "N/A". During initialisation
   *  these values are transformed with `parseFloat` to a number or to NaN
   */
  const prices = getButtonObjectPrices(buttonObject)

  return (
    isNaN(basePrice)
      ? prices[priceColumn] // a column index
      : prices[basePrice] // A non-NaN value is a column index
  )
}

export const hasButtonObjectCameraIndices = ({ CameraIndices }) => Array.isArray(CameraIndices)

export const getButtonObjectCameraIndices = ({ CameraIndices }) => CameraIndices

export const hasButtonObjectCamera = (buttonObject) => !isNaN(getButtonObjectCamera(buttonObject))

export const getButtonObjectCamera = ({ Camera }) => Number(Camera)

export const hasButtonObjectWebGLCamIDs = ({ WebGLCamIDs }) => Array.isArray(WebGLCamIDs)

export const getButtonObjectWebGLCamIDs = ({ WebGLCamIDs }) => WebGLCamIDs

export function getIsConfigured (configuration) {
  /**
   *  log('getIsConfigured')
   */

  const CONFIGURATION = configuration
    .split('+')
    .filter(isTruthy)
    .map(toRule)

  return function isConfigured (buttonObject) {
    if (hasButtonObjectFrontEndRule(buttonObject)) {
      return (
        CONFIGURATION.some((frontEndRule) => frontEndRule === getButtonObjectFrontEndRule(buttonObject))
      )
    }

    return false
  }
}

export function getIsBackendConfigured (backendConfiguration) {
  /**
   *  log('getIsBackendConfigured')
   */

  const BACKENDCONFIGURATION = backendConfiguration
    .split('+')
    .filter(isTruthy)
    .map(toRule)

  return function isBackendConfigured (buttonObject) {
    if (hasButtonObjectFrontEndRule(buttonObject)) {
      return (
        BACKENDCONFIGURATION.some((frontEndRule) => frontEndRule === getButtonObjectFrontEndRule(buttonObject))
      )
    }

    return false
  }
}

export function getIsDefaultConfigured (configuration) {
  /**
   *  log('getIsDefaultConfigured')
   */

  return function isDefaultConfigured (buttonObject) {
    if (hasButtonObjectDefaultRule(buttonObject)) {
      return (
        evaluate(getButtonObjectDefaultRule(buttonObject), configuration)
      )
    }

    return false
  }
}
