import debug from 'debug'

import {
  getConfiguration,
  getPriceColumnRules
} from '~/client/assets/js/common/render-data'

const log = debug('renderapp:render-data/price-column')

log('`renderapp` is awake')

/**
 *  Computing the price column isn't especially expensive when it's executed once,
 *  but it's executed hundreds of times per `change` event to get the same result. Ouch
 *
 *  Let's cache it
 */
export const priceColumnMap = new Map()

export default function getPriceColumn () {
  /**
   *  log('getPriceColumn')
   */

  const configuration = getConfiguration()

  let priceColumn

  /**
   *  If we have seen this configuration before then we have computed the
   *  price column before and stored it in the cache
   *
   *  Does the cache have this configuration?
   */
  if (priceColumnMap.has(configuration)) {
    /**
     *  Yes, the cache has it!
     *
     *  Let's get it from the cache
     */
    priceColumn = priceColumnMap.get(configuration)
  } else {
    /**
     *  No, the cache does not have it (we have not seen this configuration before)!
     *
     *  Let's compute it, then cache it
     *
     *  The configuration is a string whose characters may or may not be in alphabetical order
     *
     *      +A+C+D+H+E+I+F
     *
     *  Split the string on the "+" character to create an array of strings
     */
    const CONFIGURATION = configuration.split('+')

    /**
     *  `CONFIGURATION` is an array of strings
     *
     *      ['', 'A', 'C', 'D', 'H', 'E', 'I', 'F']
     *
     *   Get the price column rules as an array
     */
    const priceColumnRules = getPriceColumnRules()

    /**
     *  `priceColumnRules` is an array of strings
     *
     *      [ '+A+C+B', '+D+F+E', '+I+H+G' ]
     *
     *  So!
     *
     *  Each `priceColumnRule` is also a string which may or may not be in alphabetical order
     *
     *  Each `priceColumnRule` looks an awful lot like the `configuration`
     *
     *  We want to find the `priceColumnRule` where every rule is contained in `configuration`
     *
     *  The price column is that index
     */
    priceColumn = (
      priceColumnRules
        .findIndex((priceColumnRule) => priceColumnRule.split('+').every((s) => CONFIGURATION.includes(s)))
    )

    /**
     *  Now set it into the cache
     */
    priceColumnMap.set(configuration, priceColumn)
  }

  /**
   *  Return the price column
   */
  return priceColumn
}
