import debug from 'debug'

import {
  isSelected,
  isDisabled,

  hasButtonObjectRangeColoursParent,
  getButtonObjectRangeColoursParent,

  hasButtonObjectUIElementContainer,
  getButtonObjectUIElementContainer,

  getButtonObjectPrice
} from '~/client/assets/js/renderapp/render-data/button-object'

import {
  getAllButtonObjects,

  getRangesFromButtonObjects,
  getRangesFrom,

  getButtonObjectsForRange,

  hasAnyButtonObjectsSelectedForRange,
  hasAllButtonObjectsDisabledForRange
} from '~/client/assets/js/renderapp/render-data/button-objects'

import getPriceColumn from '~/client/assets/js/renderapp/render-data/price-column'

import getPriceFormat from '~/client/assets/js/renderapp/render-data/price-format'

const log = debug('renderapp:render-data/ui-element-container/change')

log('`renderapp` is awake')

/**
 *  Update
 */

export function updateRangeColoursParentClassList ({ RangeColoursParent: { classList } = {} } = {}, params = {}) {
  /**
   *  log('updateRangeColoursParentClassList')
   */

  if (classList) {
    const {
      selected = false,
      disabled = false
    } = params

    if (selected) {
      classList.add('selected')
    } else {
      classList.remove('selected')
    }

    if (disabled) {
      classList.add('disabled')
    } else {
      classList.remove('disabled')
    }
  }
}

export function updateRangeColoursParentPrice (buttonObject, price = 0) {
  /**
   *  log('updateRangeColoursParentPrice')
   */

  if (hasButtonObjectRangeColoursParent(buttonObject)) {
    const priceNode = getButtonObjectRangeColoursParent(buttonObject)
      .querySelector('.price') // h6 .price
    if (priceNode) renderPrice(price, priceNode)
  }
}

export function updateUIElementContainerClassList ({ UIElementContainer: { classList } = {} } = {}, params = {}) {
  /**
   *  log('updateUIElementContainerClassList')
   */

  if (classList) {
    const {
      selected = false,
      disabled = false
    } = params

    if (selected) {
      classList.add('selected')
    } else {
      classList.remove('selected')
    }

    if (disabled) {
      classList.add('disabled')
    } else {
      classList.remove('disabled')
    }
  }
}

export function updateUIElementContainerPrice (buttonObject, price = 0) {
  /**
   *  log('updateUIElementContainerPrice')
   */

  if (hasButtonObjectUIElementContainer(buttonObject)) {
    const priceNode = getButtonObjectUIElementContainer(buttonObject)
      .querySelector('.price')
    if (priceNode) renderPrice(price, priceNode)
  }
}

/**
 *  `ButtonObject`
 */

export function updateButtonObjectForRangeRangeColoursParentClassList (range) {
  /**
   *  log('updateButtonObjectForRangeRangeColoursParentClassList')
   */

  /**
   *  Use the first `buttonObject` in the range:
   */
  const [
    buttonObject
  ] = getButtonObjectsForRange(range) // we render for 'N/A'

  if (buttonObject) {
    const selected = hasAnyButtonObjectsSelectedForRange(range)
    const disabled = hasAllButtonObjectsDisabledForRange(range)

    updateRangeColoursParentClassList(buttonObject, { selected, disabled })
  }
}

export function updateButtonObjectForRangeRangeColoursParentPrice (range) {
  /**
   *  log('updateButtonObjectForRangeRangeColoursParentPrice')
   */

  /**
   *  Use the first `buttonObject` in the range:
   */
  const [
    buttonObject
  ] = getButtonObjectsForRange(range) // we render for 'N/A'

  if (buttonObject) {
    const priceColumn = getPriceColumn()
    const price = getButtonObjectPrice(buttonObject, priceColumn)

    updateRangeColoursParentPrice(buttonObject, price)
  }
}

export function updateButtonObjectUIElementContainerClassList (buttonObject) {
  /**
   *  log('updateButtonObjectUIElementContainerClassList')
   */

  const selected = isSelected(buttonObject)
  const disabled = isDisabled(buttonObject)

  updateUIElementContainerClassList(buttonObject, { selected, disabled })
}

export function updateButtonObjectUIElementContainerPrice (buttonObject) {
  /**
   *  log('updateButtonObjectUIElementContainerPrice')
   */

  const priceColumn = getPriceColumn()
  const price = getButtonObjectPrice(buttonObject, priceColumn)

  updateUIElementContainerPrice(buttonObject, price)
}

/**
 *  `ButtonObjects`
 */

export function updateAllButtonObjectsRangeRangeColoursParentClassList () {
  /**
   *  log('updateAllButtonObjectsRangeRangeColoursParentClassList')
   */

  getRangesFromButtonObjects()
    .forEach(updateButtonObjectForRangeRangeColoursParentClassList)
}

export function updateButtonObjectsRangeRangeColoursParentClassList (buttonObjects = []) {
  /**
   *  log('updateButtonObjectsRangeRangeColoursParentClassList')
   */

  getRangesFrom(buttonObjects)
    .forEach(updateButtonObjectForRangeRangeColoursParentClassList)
}

export function updateAllButtonObjectsRangeRangeColoursParentPrice () {
  /**
   *  log('updateAllButtonObjectsRangeRangeColoursParentPrice')
   */

  getRangesFromButtonObjects()
    .forEach(updateButtonObjectForRangeRangeColoursParentPrice)
}

export function updateButtonObjectsRangeRangeColoursParentPrice (buttonObjects = []) {
  /**
   *  log('updateButtonObjectsRangeRangeColoursParentPrice')
   */

  getRangesFrom(buttonObjects)
    .forEach(updateButtonObjectForRangeRangeColoursParentPrice)
}

export function updateAllButtonObjectsUIElementContainerClassList () {
  /**
   *  log('updateAllButtonObjectsUIElementContainerClassList')
   */

  getAllButtonObjects()
    .forEach(updateButtonObjectUIElementContainerClassList)
}

export function updateButtonObjectsUIElementContainerClassList (buttonObjects = []) {
  /**
   *  log('updateButtonObjectsUIElementContainerClassList')
   */

  buttonObjects
    .forEach(updateButtonObjectUIElementContainerClassList)
}

export function updateAllButtonObjectsUIElementContainerPrice () {
  /**
   *  log('updateAllButtonObjectsUIElementContainerPrice')
   */

  getAllButtonObjects()
    .forEach(updateButtonObjectUIElementContainerPrice)
}

export function updateButtonObjectsUIElementContainerPrice (buttonObjects = []) {
  /**
   *  log('updateButtonObjectsUIElementContainerPrice')
   */

  buttonObjects
    .forEach(updateButtonObjectUIElementContainerPrice)
}

/**
 *  Render
 */

export function renderAllButtonObjects () {
  log('renderAllButtonObjects')

  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  requestAnimationFrame(updateAllButtonObjectsRangeRangeColoursParentClassList)

  requestAnimationFrame(updateAllButtonObjectsUIElementContainerClassList)

  requestAnimationFrame(updateAllButtonObjectsRangeRangeColoursParentPrice)

  requestAnimationFrame(updateAllButtonObjectsUIElementContainerPrice)
}

export function renderButtonObjects (buttonObjects = []) {
  log('renderButtonObjects')

  const {
    requestAnimationFrame = function requestAnimationFrame () {
      log('`requestAnimationFrame` is not available')
    }
  } = global

  requestAnimationFrame(() => updateButtonObjectsRangeRangeColoursParentClassList(buttonObjects))

  requestAnimationFrame(() => updateButtonObjectsUIElementContainerClassList(buttonObjects))

  requestAnimationFrame(() => updateButtonObjectsRangeRangeColoursParentPrice(buttonObjects))

  requestAnimationFrame(() => updateButtonObjectsUIElementContainerPrice(buttonObjects))
}

export function renderPrice (price, priceNode) {
  /**
   *  log('renderPrice')
   */

  let priceText

  if (isNaN(price)) {
    priceText = 'N/A'
  } else {
    const formattedPrice = (
      getPriceFormat()
        .format(price)
    )

    priceText = (
      '+' + String.fromCharCode(160) + formattedPrice
    )
  }

  priceNode.innerText = priceText
}
