import debug from 'debug'

import Renderapp from './renderapp'

const log = debug('renderapp:home')

log('`renderapp` is awake')

global.Renderapp = Renderapp

const element = document.getElementById('render-data')
if (element) {
  const {
    textContent = '{}'
  } = element
  const renderData = JSON.parse(textContent)

  log(renderData)

  Renderapp.Init(renderData)
}
